import styled from "styled-components";
import backgroundImg from "../../Images/home/blue-background.jpg";
import { THEME } from "../../theme";

export const Maindiv = styled.div`
  width: 1440px;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    width: 1140px;
    margin: 0 auto;
  } 

  @media screen and (max-width: 1140px) {
    width: 1024px;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const SectionTitle = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: #020202;
    padding-top: 10px;

    @media screen and (min-width:768px) {
      font-size: 42px;
    }
`

export const ContentHolder = styled.div`
    padding:2rem 0;
    display:flex;
    flex-direction:column;
    gap:15px;

 .holder{
  h3{
    font-size: 16px;
    font-weight: 600;
    color: #020202;
      @media screen and (min-width:768px) {
          font-size: 20px;
        }
  }
  p{
    font-size: 14px;
    color: #817e7e;
    line-height: 24px;
      @media screen and (min-width:768px) {
          font-size: 16px;
          line-height: 30px;
        }
  }
 }
`

export const SectionSubTitle = styled.h6`
  font-size: 16px;
  font-weight: 500;
  color: #1a1616;

  span{
    color:red;
  }
`



export const Section = styled.section`
  padding-block: 5rem 1rem; //-> Section
  background:${THEME.app_primary};
  min-height:calc(100vh - 70px);
  display:grid;
  place-items: center;
  

  .home__container{
    /* padding-top:2.5rem; */
    margin:auto;
    row-gap:2rem;
    display: grid; //-> Grid
    gap: 1.5rem;
    max-width: 1120px; //-> container
    margin-inline: 1.5rem;
    .home__img{
      width:100%;
      filter:drop-shadow(0 0 48px hsla(93, 54%, 54%, .4));
      justify-self:center;
    }

    .home__data{
      text-align:left;

      .home__logo{
        max-width:60px;
      }
      .home__title{
        font-size: 2.25rem;
        font-weight: 700;
        color: #fff;
        margin-bottom:1rem;
      }
      .home__description{
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        margin-bottom:3rem;
      }
      .home__buttons{
        display:flex;
        align-items:center;
        justify-content:center;
        column-gap:.5rem;

        .button{
          background-color: #ed2d44;
          color: #fff;
          border: 0;
          border-radius: 3px;
          font-weight:600;
          padding: 15px 30px 15px 30px;
          cursor: pointer;
          transition:0.3s ease-out;
          text-decoration:none;
          transform-origin:left;

          &:hover{
            transform:translateY(-5px);
            letter-spacing:.1rem;
          }
        }
      }
    }

    .home__form-sec{
      background-color:#fff;
      padding:15px;
      border-radius:10px;
      
      .home__title{
        font-size: 2.25rem;
        font-weight: 700;
        color: #fff;
        text-align:center;
        margin-bottom:1rem;
        background:#279E96;
      }
    }
  }

@media screen and (max-width: 320px){
  .home__container{
    margin-inline: 1rem;
      .home__data {
        .home__title{
          font-size: 2rem;
        }
        .home__description{
          font-size: 14px;
        }
      }
  }
}

  @media screen and (min-width: 576px){
    .home__container{
        grid-template-columns:400px;
        justify-content:center;
    }
  }
  @media screen and (min-width: 768px){
    .home__container{
        grid-template-columns:268px 380px;
        justify-content:center;
        align-items:center;
        column-gap:2rem;
    }
  }
  @media screen and (min-width: 1150px){
    .home__container{
        grid-template-columns:480px 535px;
        justify-content:center;
        column-gap:4rem;
    }
  }
`

export const SaleSection = styled.section`
  padding-block: 5rem 1rem; //-> Section
  display:grid;
  place-items: center;

  .home__container{
    /* padding-top:2.5rem; */
    margin:auto;
    row-gap:3rem;
    display: grid; //-> Grid
    gap: rem;
    max-width: 1120px; //-> container
    margin-inline: 1.5rem;

    .hero__img{
      width:100%;
    }

    .content__holder{
      p{
      color: #817e7e;
      line-height: 24px;
      padding-top: 10px; 
      font-size: 14px; 
      
      @media screen and (min-width:768px) {
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
  @media screen and (min-width: 576px){
    .home__container{
        grid-template-columns:400px;
        justify-content:center;
    }
  }
  @media screen and (min-width: 768px){
    .home__container{
        grid-template-columns:700px;
        justify-content:center;
        column-gap:2.5rem;
    }
  }
  @media screen and (min-width: 1150px){
    .home__container{
        grid-template-columns:1100px;
        justify-content:center;
        column-gap:4rem;
    }
  }
`

export const ClientSection = styled.section`
  padding-block: 5rem 1rem; //-> Section
    display:grid;
    place-items: center;

    .client__container{
      /* padding-top:2.5rem; */
      margin:auto;
      row-gap:2rem;
      display: grid; //-> Grid
      max-width: 1120px; //-> container
      margin-inline: 1.5rem;
      place-items:center;

      .client__row{
        display:flex;
        align-items:center;
        justify-content:center;
        column-gap:50px;
        row-gap:10px;
        flex-wrap:wrap;

        h6{
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap:10px;
      @media screen and (min-width:768px) {
          font-size: 20px;
        }
        }
      }

      .content__holder{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap:20px;

        .content__erapper{
          padding:20px 15px;
          height:100%;
          border-radius: 0 20% 0 20%; 
          box-shadow: #007bff55 0px 2px 8px 0px;
          /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
          cursor: pointer;
          transition:0.5s ease-out;

          &:hover{
            transform:translateY(-5px);box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
          }
          &:hover{
            h4{
              color:${THEME.primary};
            }
            p{
              color:#000;
            }
          }

          & span{
            width: 45px;
            height: 45px;
            background:${THEME.primary};
            border-radius:50%;
            display:block;
            display:grid;
            place-items:center;
            color:#fff;
            margin-bottom:1rem;
          }

          & h4{
            font-size:16px;
            color:#000;
            margin-bottom:1rem;
            transition:0.3s ease-out;
            font-weight:600;
          }
          & p{
            font-size:14px;
            color:#817e7e;
            margin-bottom:1rem;
            transition:0.3s ease-out;
            font-weight:600;
          }
        }
      }
    }
`

export const VideoSectionHolder = styled.section`
    padding-block: 5rem 1rem; //-> Section
    display:grid;
    place-items: center;

    .video__container{
      /* padding-top:2.5rem; */
      margin:auto;
      row-gap:2rem;
      display: grid; //-> Grid
      max-width: 1120px; //-> container
      margin-inline: 1.5rem;
      place-items:center;

      p{
        font-size:16px;
        font-weight:500;
        color:#000;
        text-align:center;
      }
    }
`

export const PrintSection = styled.section`

    padding-block: 5rem 1rem; //-> Section
    display:grid;
    place-items: center;

    .print__container{
      /* padding-top:2.5rem; */
      margin:auto;
      row-gap:2rem;
      display: grid; //-> Grid
      max-width: 1120px; //-> container
      margin-inline: 1.5rem;
      place-items:center;
      text-align:center;

      .container__holder{
        width:100%;
        flex:auto;
      }

      img{
        max-width:100%;
      }
    }
`

export const VideoHolder = styled.div`
    display:flex;
      align-items:center;
      justify-content:center;
      overflow:hidden;
      /* border: 2px solid ${THEME.primary}; */
      /* padding: 10px; */
      border-radius: 12px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      position:relative;
      width:100%;

      .dark{
        position:absolute;
        inset:0;
        z-index:10;
        
      }
  & video{
      width:100%;
      height:100%;
      vertical-align:middle;
      display:inline-block;
      object-fit:cover;
  }

  & .lock{
    display:flex;
    width: 100%;
    /* height: 400px; */
    padding:4rem 1rem;
    background:#cad4d9;  /* fallback for old browsers */

    align-items:center;
    justify-content:center;
    flex-direction:column;
    gap:20px;
    
    & .title{
      font-size:1.85rem;
      color:#1e3b57;
    }
    & .btn{
      padding:10px;
      background:#0e3a57;
      display:flex;    
      align-items:center;
      justify-content:center;
      border-radius:6px;
      cursor: pointer;
      color:#fff;
      font-size:1rem;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      transition: 0.5s ease-out;
      
      &:hover{
        transform:translateY(-8px);
      }
    }
  }
`
//second section

export const SecMainSec = styled.div`
  display: flex;
  text-align: center;
  padding: 100px 0px 30px 0px;
  @media screen and (max-width: 992px) {
    padding: 80px 0px 30px 0px;
  }
`;


export const SecContent = styled.div`
  .subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #1a1616;
  }
  .circle{
    width: fit-content;
    margin: auto;
    padding: 20px 24px;
    border-radius: 50%;
    background-color: red;
  }
  .title {
    font-size: 42px;
    font-weight: 600;
    color: #020202;
    padding-top: 10px;
  }
  .subtitle2 {
    font-size: 16px;
    color: #817e7e;
    line-height: 32px;
    padding-top: 10px;
  }
  .row {
    margin-top: 30px;
  }
  .col {
    border: 1px solid gray;
    padding: 100px 20px;
  }
  .colPara {
    padding-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #020202;
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 24px;
    }
    .subtitle2 {
      font-size: 12px;
    }
  }
`;

//Third Section

export const ThirdMainSec = styled.section`

  padding-block: 5rem 1rem; //-> Section
  .subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #1a1616;
  }
  .title {
    font-size: 42px;
    font-weight: 600;
    color: #020202;
    padding-top: 10px;
  }
  .subtitle2 {
    font-size: 16px;
    color: #817e7e;
    line-height: 32px;
    padding-top: 10px;
  }
  .subtitle3 {
    font-size: 13px;
    color: #817e7e;
    line-height: 26px;
    padding-top: 10px;
  }
  img {
    width: 100%;
  }
  .col-1 {
    background-color: #fcfbff;
    border: 1px solid #e0d7fa;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 40px 10px;
  }
  .col-2 {
    margin-top: 30px;
    padding-left: 30px;
  }
  .colPara {
    font-size: 18px;
    font-weight: 600;
    color: #1a1616;
  }
  .row{
    margin-top: 20px;
    gap: 10px;
  }
  .col {
    border: 1px solid #e0d7fa;
    border-radius: 24px;
    padding: 20px 40px;
  }
  .row {
    /* gap: 20px; */
    margin-top: 20px;
  }
  .number {
    font-size: 38px;
    font-weight: 500;
    color: #020202;
  }

  @media screen and (max-width: 1022px) {
    .col-2 {
    padding-left: 0px;
  }
  .row{
    justify-content: center;
  }
  }

  @media screen and (max-width: 600px) {
    text-align: center;
    .row {
      justify-content: center;
      gap: 14px;
    }
    .title {
      font-size: 24px;
    }
    .subtitle2 {
      font-size: 12px;
    }
    .number {
    font-size: 24px;
  }
  }
`;

export const ThirdSectionWrap = styled.div`
margin:30px 180px;
  & h2{
    text-align: center;
    margin:30px 0px;
    color:${THEME.black}
  }
  & h3{
   padding-bottom: 20px;
   color: ${THEME.primary};
  }

  & p{
  font-size: 16px;
  line-height:30px;
  }

    & img{
    width:400px;
    margin-bottom: 20px;
    }

  .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px ;
    margin-bottom: 20px;

  }
  .img{
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1100px) {
    margin: 20px;
  }

`

//Fourth Section

export const FrtMainSec = styled.div`
  margin-top: 100px;
  padding: 70px 0px;
  background-color: #f5f6ff;
`;

export const FrtContent = styled.div`
  .subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #1a1616;
    text-align: center;
  }
  .title {
    font-size: 42px;
    font-weight: 600;
    color: #020202;
    padding-top: 10px;
    text-align: center;
  }
  .para {
    font-size: 16px;
    color: #1a1616;
    font-weight: 700;
  }
  .instructionBox {
    text-align: center;
    display: flex;
    gap: 100px;
    border: 1px solid black;
    padding: 30px 75px;
    width: max-content;
    border-radius: 65px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  img {
    width: 100%;
  }
  .col-1 {
    background-color: #fcfbff;
    border: 1px solid #e0d7fa;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 40px 0px;
  }
  .col-2 {
    margin-top: 10px;
    padding: 0px 100px;
  }
  .titlee {
    font-size: 42px;
    font-weight: 600;
    color: #020202;
    padding-top: 10px;
  }
  .colPara {
    font-size: 20px;
    font-weight: 600;
    color: #020202;
  }
  .subtitle2 {
    font-size: 16px;
    color: #817e7e;
    line-height: 32px;
    padding: 10px 20px 10px 0px;
  }
  .hov-para {
    padding-top: 20px;
    padding: 10px 20px;
  }
  /* .hov-para:hover {
    background-color: #fff;
    border: 1px solid black;
    border-radius: 20px;
  } */

  @media screen and (max-width: 992px) and (min-width: 768px) {
    .para {
      font-size: 14px;
    }
    .instructionBox {
      gap: 30px;
      padding: 13px 50px;
    }
    .title {
      font-size: 28px;
    }
    .titlee {
      font-size: 32px;
      padding-top: 30px;
    }
    .hov-para {
      padding: 15px 0px;
    }
  }
  @media screen and (max-width: 767px) {
    .para {
      font-size: 14px;
    }
    .instructionBox {
      display: none;
    }
    .title {
      font-size: 24px;
    }
    .titlee {
      font-size: 20px;
      padding-top: 30px;
    }
    .hov-para {
      padding: 15px 0px;
    }
    .colPara {
      font-size: 16px;
    }
    .subtitle2 {
      font-size: 14px;
    }
    .col-2 {
      padding: 0px 20px;
    }
    .col-1 {
    padding: 40px 0px;
    margin: 20px 0px;
  }
  }
`;

//Fifth Section

export const FifMainSec = styled.div`
  background:#1321a4;
  padding:5rem 20px;

    .container{
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      gap:30px;
      padding:0 2rem;

      h2{
        font-size:40px;
        font-weight:600;
        color:#fff;
      }

      h3{
        font-size:20px;
        font-weight:500;
        color:#fff;
        text-align:center;
      }
      .wrapper{
        display: flex;
        align-items:center;
        justify-content:flex-start;
        gap:20px;

        img{
          width:30px;
        }
        p{
          font-size:14px;
              font-weight:500;
              color:#fff;
        }
      }
      @media screen and (max-width: 767px)
      {
        h2 {
          font-size: 20px;
        }
        h3{
          font-size:16px;
        }
      }
    }
  
`;

//Sixth Section

