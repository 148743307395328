import React from "react";
import { ContentHolder, PrintSection, SectionTitle } from "../style";
import { Col, Row } from "antd";
import print from '../../../assets/print.png'

export const FourthSection = () => {
  return (
    <PrintSection>
      <div className="print__container">
        {/* <SectionTitle> Best Graphic Interface Through Latest Application Showing</SectionTitle>

        <div className="container__holder">
          <Row >
            <Col span={24} lg={12}>
              <ContentHolder>
                <div className="holder">
                  <h3>Enhance your billing experience</h3>
                  <p>Hi Billing combines invoicing, expense management, 
                    project billing, and recurring billing - all in one 
                    place, handling all the heavy lifting for you.</p>
                </div>
              </ContentHolder>
              </Col>
            <Col span={24} lg={12}>
              <ContentHolder>
                <div className="holder">
                  <h3>Promise of security and compliance</h3>
                  <p>At Hi Biller, we put privacy and security first. 
                    We recognize the importance of finding the perfect 
                    balance between safeguarding your customers' data and 
                    empowering your employees to work efficiently. 
                    Hi Billing meets both of these.</p>
                </div>
              </ContentHolder>
              </Col>
          </Row>
        </div> */}

        <img src={print} alt="Hi Biller Invoice Template Design" title="Hi Biller Invoice Template Design" />
      </div>
    </PrintSection>
    // <FrtMainSec>
    //   <FrtContent>
    //     <p className="subtitle">
    //       <span style={{ color: "red" }}># </span> Installing & Enjoying Process
    //     </p>
    //     <p className="title">
    //       Get the Full Process System <br /> use Our Softwere
    //     </p>
    //     <Row>
    //       <Col className="col-1" lg={12}>
    //         <img src={img} alt="" />
    //       </Col>
    //       <Col className="col-2" lg={12}>
    //         <p className="titlee">
    //           Best Graphic Interface Through Latest Application Showing
    //         </p>
    //        <div className="hov-para"> 
    //         <p className="colPara">Set your personalized spending budget</p>
    //         <p className="subtitle2">Rhoncus dignissim habitant viverra mollis. Semper ullamcorper ac pharetra, commodo mauris. Fringilla.</p>
    //        </div>
    //        <div className="hov-para"> 
    //         <p className="colPara">Set your personalized spending budget</p>
    //         <p className="subtitle2">Rhoncus dignissim habitant viverra mollis. Semper ullamcorper ac pharetra, commodo mauris. Fringilla.</p>
    //        </div>
    //        <div className="hov-para"> 
    //         <p className="colPara">Set your personalized spending budget</p>
    //         <p className="subtitle2">Rhoncus dignissim habitant viverra mollis. Semper ullamcorper ac pharetra, commodo mauris. Fringilla.</p>
    //        </div>
    //       </Col>
    //     </Row>
    //   </FrtContent>
    // </FrtMainSec>
  );
};
