
import React from 'react'
import styled from 'styled-components'
import { Checkbox as AntdCheckbox, Form } from 'antd'

const StyledCheckbox = styled(AntdCheckbox)`
  & .ant-checkbox .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius:50%;
    /* background: ${props => props.color || 'black'}; */

    &:hover {
      /* background: ${props => props.color || 'black'}; */
      /* border-color: ${props => props.color || 'black'}; */
    }
  }

  & .ant-checkbox .ant-checkbox-inner:after {
    /* inset-inline-start: 25%; */
  }

  & .ant-checkbox .ant-checkbox-inner:after{
    inset-inline-start: 30%;
    width: 7px;
    height: 14px;
  }

  &.ant-checkbox-wrapper {
    align-items: center;
    height: 100%;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    /* background-color: ${props => props.color || 'black'}; */
    /* border-color: ${props => props.color || 'black'}; */
  }
  
  .ant-checkbox + span {
    padding-left: 0px;
  }
`
const LabelWrapper = styled.div`
  font-size: 14px;
  line-height: 24px;
`

export const CustomCheckBox = ({ onChange, label, checked, name, color,  ...rest }) => {

   return (
    <Form.Item name={name} valuePropName="checked">
      <StyledCheckbox
        {...rest}
        checked={checked}
        color={color}
        onChange={onChange}
      >
        <LabelWrapper>{label}</LabelWrapper>
      </StyledCheckbox>
    </Form.Item>
  )
}