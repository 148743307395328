import info1 from './SecondaySection/heartsvg.svg'
import info2 from './SecondaySection/folder.svg'
import info3 from './SecondaySection/flower.svg'
import info4 from './SecondaySection/users.svg'

import price1 from './Pricing/flatPricing.svg'
import price2 from './Pricing/VolumePricing.svg'
import price3 from './Pricing/QuantityBased.svg'
import price4 from './Pricing/StairstepPricing.svg'
import price5 from './Pricing/TieredPricing.svg'

import { FaRegClock } from "react-icons/fa";
import { FaUsersCog } from "react-icons/fa";
import { FaBook } from "react-icons/fa";

export const testimonialDAta = [
    {
        key:'1',
        content:"I have been using Hi Biller for my business, and I couldn't be more pleased with the results. This software has transformed the way we handle billing and invoicing, making the entire process streamlined and efficient.",
    },
    {
        key:'2',
        content:"The user-friendly interface of Hi Biller makes it easy to create and send professional-looking invoices to our clients. The customization options allow us to brand our invoices and tailor them to our specific needs. This has not only saved us time but has also enhanced our professional image.",
    },
    {
        key:'3',
        content:"One standout feature of Hi Biller is its robust reporting capabilities. The detailed financial reports provide valuable insights into our revenue, expenses, and overall financial health. This has been instrumental in making informed business decisions and planning for the future.",
    },
    {
        key:'4',
        content:"The subscription management tools are another highlight. Hi Biller seamlessly handles recurring billing, automating the process and reducing the likelihood of errors. This has significantly improved our cash flow and customer satisfaction.",
    },
    {
        key:'5',
        content:"The customer support team at Hi Biller has been exceptional. They are responsive, knowledgeable, and always ready to assist with any questions or concerns. It's reassuring to know that we have a dedicated support system backing us up.",
    },
    {
        key:'6',
        content:"The automation features have saved me countless hours, allowing me to focus more on growing my business. The recurring billing functionality is especially convenient for managing subscription-based services, ensuring accuracy and timeliness in every transaction.",
    },
    {
        key:'7',
        content:"The subscription management tools are another highlight. Hi Biller seamlessly handles recurring billing, automating the process and reducing the likelihood of errors. This has significantly improved our cash flow and customer satisfaction.",
    },
    {
        key:'8',
        content:"The customer support team at Hi Biller has been exceptional. They are responsive, knowledgeable, and always ready to assist with any questions or concerns. It's reassuring to know that we have a dedicated support system backing us up.",
    },
    {
        key:'9',
        content:"The automation features have saved me countless hours, allowing me to focus more on growing my business. The recurring billing functionality is especially convenient for managing subscription-based services, ensuring accuracy and timeliness in every transaction.",
    }
]

export const infoData=[
    {
        key:'1',
        logo:info1,
        content:'A user-friendly dashboard built for you, not your accountant.',
        altternative:'user-friendly'
    },
    {
        key:'2',
        logo:info2,
        content:"Peace of mind that you're always orgainized ahead of tax season.",
        altternative:'orgainized'
    },
    {
        key:'3',
        logo:info3,
        content:'A complete picture of your business health, wherever you are.',
        altternative:'business'
    },
    {
        key:'4',
        logo:info4,
        content:'Our in-house team of bookkeeping, accounting, and payroll coaches.',
        altternative:'bookkeeping'
    },
]

export const salepdata =[
    {
        kay:'1',
        title:"Simplify trial management",
        content:"Set up, customize, and extend free trials. Keep prospects engaged, and maximize conversion opportunities by automating reminder emails.",
    },
    {
        kay:'2',
        title:"Power profits through Project Billing",
        content:"Track hours spent on projects and bill your clients. Stay within budget with real-time monitoring of project expenses and work hours through a centralized dashboard.",
    },
    {
        kay:'3',
        title:"Unlock new revenue possibilities",
        content:"Test your curated plans with various pricing models like flat, volume, and tiered strategies to optimize your revenue potential.",
    },
    {
        kay:'4',
        title:"Change prices without losing demand",
        content:"Declutter your product catalogue and do pricing experiments. With price lists, set custom rates for specific customers or items, adjusting prices up or down.",
    }
]

export const clienttitle=[
    {
        key:'1',
        title:'Send professional invoices'
    },
    {
        key:'2',
        title:'Get paid fast via credit card'
    },
    {
        key:'3',
        title:'Let Wave send overdue reminders'
    },
]

export const clientData=[
    {
        key:'1',
        icon:<FaRegClock size={22}/>,
        title:'Create invoices quickly',
        content:"Every invoice paid means more revenue coming into your small business. Create and send professional invoices to your customers in seconds.",
    },
    {
        key:'2',
        icon:<FaUsersCog size={22}/>,
        title:'Recurring billing for repeat customers',
        content:'Get paid on time, every time. Set up recurring invoices and automatic credit card payments for your repeat customers and stop chasing payments. Switch between automatic and manual billing whenever you want.',
    },
    {
        key:'3',
        icon:<FaBook size={22}/>,
        title:'Your bookkeeping is already done',
        content:'All your invoicing and payment information automatically syncs with the free Wave Accounting software included with your account.',
    },
]

export const pricingList = [
    {
        title:'Flat Pricing',
        svg:price1,
    },
    {
        title:'Volume Pricing',
        svg:price2,
    },
    {
        title:'Quantity Based',
        svg:price3,
    },
    {
        title:'Stairstep Pricing',
        svg:price4,
    },
    {
        title:'Tiered Pricing',
        svg:price5,
    },
]

// About Us
export const serviceData = [
    {
        key:'1',
        title:'Creative & Innovative',
        content:'Building innovative Software solutions to help Clients stay top in the business',
        order: 1,
    },
    {
        key:'2',
        title:"100% Client Satisfaction",
        content:'At every walk of the project, we ensure client satisfaction as our mantra',
        order: 0,
    },
    {
        key:'3',
        title:'Quality First',
        content:'Striving hard to offer best standard solutions to align with our clients’ goals and dreams',
        order: 1,
    },

]

export const factsData = [
    {
        key:'1',
        content:'We are Ideaux technologies started in 2022. We have skilled in various top technologies such as Java, Django, Reactjs, Php ',
        order: 1,
    },
    {
        key:'2',
        content:'Our end-to-end application development solutions are meticulously designed to consistently outdeliver your expectations. Our skilled team and advanced technology create bespoke software, ensuring seamless integration and enhanced user experience.',
        order: 0,
    },
    {
        key:'3',
        content:'Our team diligently strives to honour commitments and always comes out with flying colours.  All our team members take ownership of their goals and responsibility of their actions, even if it means taking responsibility of their shortcomings',
        order: 1,
    },
    {
        key:'4',
        content:'Developing rich and user-friendly iOS, Android, and Hybrid applications by leveraging native and cross-platform technologies.',
        order: 0,
    },
    {
        key:'5',
        content:'Creating tech driven solutions for both global brands and small businesses alike. We consider technology as a tool to create value for our clients.',
        order: 1,
    },
]