import React, { Fragment } from "react";
import img from "../../../assets/sale.png";
import { SaleSection, SectionTitle, SectionSubTitle, ContentHolder, ThirdSectionWrap } from "../style";
import { salepdata } from "../../../assets/data";
import { CustomRow } from "../../../components/CustomRow";
import { Col } from 'antd'
import card1 from '../../../Images/card1.webp'
import card2 from '../../../Images/card2.webp'
import card3 from '../../../Images/card3.webp'
import card4 from '../../../Images/card4.webp'
import card5 from '../../../Images/card5.webp'
import card6 from '../../../Images/card6.webp'
import card7 from '../../../Images/card7.webp'

export const ThirdSection = () => {
  return (
    <ThirdSectionWrap>
      <h2>All the features you need in invoicing software</h2>
      <CustomRow space={[24, 24]}>
        <Col className="img" span={24} md={12} >
          <img src={card1} alt="Create Professional Invoices" title="Create Professional Invoices" />
        </Col>
        <Col className="text" span={24} md={12} >
          <h3>Create Professional Invoices</h3>
          <p>With our invoicing software, we offer a straightforward solution to
            enhance your professional image. Select from a variety of templates
            and personalize your invoice design, including colors, fonts,
            and layout, to align with your brand identity. Add your business
            logo, header, and footer for a consistent branded look. Our
            user-friendly interface ensures simplicity, allowing you to
            create customized invoices that reflect your business essence.
            Elevate your client interactions and make a statement about
            your business with our custom branded invoices.</p>
        </Col>

        <Col className="text" span={24} md={12} >
          <h3>More than Just Invoicing</h3>
          <p>In addition to invoices, refers empowers you to create a variety of
            essential documents tailored to your business needs. Craft
            professional quotations and estimates, generate proforma invoices,
            set up recurring invoices for seamless payments, manage credit
            and debit notes efficiently, and even create delivery challans
            to streamline your deliveries. Keep track of payments with our
            payment receipts, and streamline your procurement process with
            easy-to-create purchase orders and sales orders. With our
            invoicing software, you have the flexibility to manage
            various aspects of your business transactions effortlessly.</p>
        </Col>
        <Col className="img" span={24} md={12} >
          <img src={card2} alt="More than Just Invoicing" title="More than Just Invoicing" />
        </Col>

        <Col className="img" span={24} md={12} >
          <img src={card3} alt="Advanced Accounting & Reporting" title="Advanced Accounting & Reporting" />
        </Col>
        <Col className="text" span={24} md={12} >
          <h3>Advanced Accounting & Reporting</h3>
          <p>Create and manage ledgers, vouchers, and account groups with ease, ensuring your records are organized and accurate. Generate crucial financial statements, including Income Statements, Balance Sheets, and Trial Balance reports, giving you valuable insights into your business's financial health. Stay on top of your accounts receivable and payables effortlessly, allowing you to manage your cash flow effectively. Our platform simplifies tax compliance by enabling you to generate GST, TDS, HSN, and other necessary reports, ensuring you meet your regulatory requirements seamlessly.</p>
        </Col>

        <Col className="text" span={24} md={12} >
          <h3>Inventory & Expenses Management</h3>
          <p>Our system auto updates your inventory and expenses, ensuring you have real-time insights into your stock levels and financial records. Easily monitor inventory levels for various items, enabling you to make informed decisions about restocking. Our intuitive interface allows you to effortlessly transform invoices into accurate expense records, maintaining your financial data integrity.</p>
        </Col>
        <Col className="img" span={24} md={12} >
          <img src={card4} alt="Inventory & Expenses Management" title="Inventory & Expenses Management" />
        </Col>

        <Col className="img" span={24} md={12} >
          <img src={card5} alt="Faster Payments with Auto Reminders" title="Faster Payments with Auto Reminders" />
        </Col>
        <Col className="text" span={24} md={12} >
          <h3>Faster Payments with Auto Reminders</h3>
          <p>Refrens provides a convenient way to send reminders to clients through both WhatsApp and email, offering flexibility to improve cash flow with faster payment cycles. By leveraging these communication channels, users can gently prompt clients to fulfill outstanding payments, contributing to a more efficient and timely settlement of invoices. This feature enhances the overall invoicing process and helps businesses maintain a healthy cash flow.</p>
        </Col>

        <Col className="text" span={24} md={12} >
          <h3>Security & Data Back-Up</h3>
          <p>Benefit from secure cloud storage and backup, ensuring your valuable information is protected and accessible whenever you need it. Our role-based access control and permissions system guarantee that only authorized personnel can access sensitive data, providing an additional layer of security. Stay ahead of potential security threats with our commitment to regular software updates and up-to-date security patches.</p>
        </Col>
        <Col className="img" span={24} md={12} >
          <img src={card6} alt="Security & Data Back-Up" title="Security & Data Back-Up" />
        </Col>

        <Col className="img" span={24} md={12} >
          <img src={card7} alt="Seamless One-Click Conversions" title="Seamless One-Click Conversions" />
        </Col>
        <Col className="text" span={24} md={12} >
          <h3>Seamless One-Click Conversions</h3>
          <p>Experience unparalleled efficiency with our platform – in just one click, seamlessly transform a quotation or proforma invoice into a professional invoice. Convert invoices into credit or debit notes effortlessly, and record expenses directly from purchase orders with unmatched ease. Bid farewell to tedious manual copy-pasting tasks and say hello to automation! Our intuitive invoice software streamlines your workflow, allowing you to focus on what truly matters – growing your business.</p>
        </Col>
      </CustomRow>
    </ThirdSectionWrap>
  );
};
