
export const NavData = [
  {
    name: 'Home',
    path: "https://wps.ideaux.in/",
  },
  {
    name: "Hi Biller",
    path: "/hibiller",
  },
  // {
  //   name: "Extension",
  //   path: "/extension",
  // },
  {
    name: "Pricing",
    path: "/pricing",
  },
  {
    name: "Privacy Policy",
    path: "/privacypolicy",
  }
];