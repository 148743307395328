import { Col, Form } from 'antd'
import React, { useEffect } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import Flex from '../../../components/Flex'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import { baseRequest } from '../../../utils/request'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { setCredentials } from '../authSlice'

const MainDesign = styled.div`
    width: 500px;
    margin: auto;
    margin-top: 50px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 50px 30px;
   
    & img {
        width: 100px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    & h1 {
        font-size: 30px;
        font-weight: 900;
        color: #3498db;
        display: flex;
        margin: 40px 0;
        /* border-bottom: 1px solid; */
        justify-content: center;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        border: 20px solid;
        & h1 {
            font-size: 20px !important;
        }
    }
`

const ConfirmPassword = () => {

    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const nevigate = useNavigate()

    const CONFIRMURL = 'user/resetPassword/'

    const location = useLocation();
    const { email } = location.state || {};

    useEffect(() => {
        form.setFieldsValue({ email: email?.email });
    }, []);

    const onFinish = (values) => {
        ReEnterPassword(values)
    }

    const onFinishFailed = (error) => {
        console.log(error, 'error');
    }

    const ReEnterPassword = (values) => {
        baseRequest.post(`${CONFIRMURL}`, values)
            .then(function (response) {
                if (response.status === 201) {
                    toast.success("Password Reset Successfully")
                    form.resetFields();
                    dispatch(setCredentials(response.data))
                    const emailData = { ...values, email: values.email };
                    nevigate(`/`)
                } else {
                    toast.error('Someting went wrong')
                }

            }).catch(function (error) {
                if (error.response.status === 400) {
                    toast.error("Doesn't match password")
                }
            })
    }

    return (
        <MainDesign>
            <h1>Confirm Password</h1>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <CustomRow space={[24, 24]}>
                    <Col span={24} md={24}>
                        <CustomInput name={'email'} display={'none'} />
                        <CustomInputPassword name={'password'} placeholder={'Enter New Password'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter New Password',
                                }
                            ]}
                        />
                    </Col>
                    <Col span={24} md={24}>
                        <CustomInputPassword name={'confirm_password'} placeholder={'Enter Confirm Password'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter Confirm Password',
                                }
                            ]}
                        />
                    </Col>
                    <Flex center={'true'} gap={'20px'} margin={'auto'} style={{ marginTop: '20px' }}>
                        <ButtonStandard.Success text={'Submit'} htmlType={'submit'} />
                    </Flex>
                </CustomRow>

            </Form>
        </MainDesign>
    )
}

export default ConfirmPassword