import styled from 'styled-components';

// export const GlobalStyles = createGlobalStyle`
//   * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }

//   body {
//     font-family: sans-serif;
//     background: #eee;
//   }
// `;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #3c3c3c;
  border-bottom: 3px solid #56e388;
`;

export const HeaderTitle = styled.h1`
  color: #eee;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const FaqsContainer = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
`;

export const FaqItem = styled.div`
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

export const FaqQuestion = styled.div`
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
  color: #2b62c9;
  font-weight: 400;

  &::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: all 0.2s ease;
  }
`;

export const FaqAnswer = styled.div`
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  display: ${props => (props.open ? "block" : "none")};
`;

export const OpenFaq = styled.div`
  &.open ${FaqQuestion} {
    margin-bottom: 15px;

    &::after {
      content: "-";
    }
  }

  &.open ${FaqAnswer} {
    max-height: 1000px;
    opacity: 1;
  }
`;
