import React, { useEffect } from 'react'
import PricingSection from './Partials/PricingSection'
import styled from 'styled-components'
import request from '../../utils/request'
import Footer from '../Footer/Partials/Footer'

const SectionStyle = styled.div`
margin:5rem 0;
`


export const Extentions = () => {
     
  return (
    <SectionStyle>
      <PricingSection />
    </SectionStyle>
  )
}
