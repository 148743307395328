import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bookedUser:{}
}
const bookingSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setBooking: (state, action) => {
            state.bookedUser= action.payload
        },
    }
})

export const { setBooking } = bookingSlice.actions
export const selectCurrentBookerUser = (state) => state.booking.bookedUser;

export default bookingSlice.reducer