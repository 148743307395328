import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";

const initialState = {
    order: [],
    status: 'idle',  // 'idle' | 'loading' | 'succeeded' | 'failed'  
    error: null
}

export const getOrder = createAsyncThunk(
    "Order/Get",
    async () => {
        try {
            const response = await request.get('gate_way/my_pay_histry/');

            return [...response.data];
        }
        catch (error) {
            throw error;
        }
    }
);

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrder.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getOrder.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.order = action.payload;
            })
            .addCase(getOrder.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllOrder = (state) => state.orders.order
export const getOrderStatus = (state) => state.orders.status
export const getOrderError = (state) => state.orders.error

export const { reducer } = orderSlice;

export default orderSlice.reducer


