import { Col, Divider, Form } from 'antd'
import React from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import styled from 'styled-components'
// import ImgLoack from '../../../Images/Forgotimg.png'
import Flex from '../../../components/Flex'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { useNavigate } from 'react-router-dom'
import { baseRequest } from '../../../utils/request'
import { toast } from 'react-toastify'
import { setCredentials } from '../authSlice'
import { useDispatch } from 'react-redux'

const MainDesign = styled.div`
    width: 500px;
    margin: auto;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 50px 30px;
    @media screen and (max-width: 500px) {
        width: 100%;
        border: 20px solid;
        & h1 {
            font-size: 20px !important;
        }
    }
    & img {
        width: 100px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    & h1 {
        font-size: 30px;
        font-weight: 900;
        color: #3498db;
        display: flex;
        margin: 40px 0;
        /* border-bottom: 1px solid; */
        justify-content: center;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
`

const ForgotPassword = () => {

    const [form] = Form.useForm()

    const nevigate = useNavigate()
    const dispatch = useDispatch();

    const EMAILURL = 'user/forgotPassword/'

    const onFinish = (values) => {
        ForgotMail(values)
    }

    const onFinishFailed = (error) => {
        console.log(error, 'error');
    }

    // const HandleconfirmPassword = (values) => {
    //     const email = { ...values, email: email }
    //     nevigate('/confirmPassword', {
    //         state: { email }
    //     });
    // }

    const ForgotMail = (values) => {
        baseRequest.post(`${EMAILURL}`, values)
            .then(function (response) {
                if (response.status === 200) {
                    toast.success("Successfully, Please check your mail")
                    form.resetFields();
                    dispatch(setCredentials(response.data))
                    const emailData = { ...values, email: values.email };
                    nevigate(`/confirmPassword`, { state: { email: emailData } });
                } else {
                    toast.error('Someting went wrong')
                }

            }).catch(function (error) {
                if (error.response.status === 400) {
                    toast.error("Mail ID doesn't exist")
                }
                console.log(error, 'faild');
            })
    }

    return (
        <MainDesign style={{background:'#fff'}}>

            {/* <img src={ImgLoack} /> */}
            <h1>Forgot Password</h1>

            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <CustomRow>
                    <Col span={24} md={24}>
                        <CustomInput name={'email'} type={'email'} placeholder={'Enter Email Id'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Email Id',
                                }
                            ]}
                        />
                    </Col>
                    {/* <div onClick={() => nevigate(`/confirmPassword`)}>click</div> */}
                    <Flex center={'true'} gap={'20px'} margin={'auto'} style={{ marginTop: '20px' }}>
                        <ButtonStandard.Success text={'Submit'} htmlType={'submit'} />
                    </Flex>
                </CustomRow>

            </Form>
        </MainDesign>
    )
}

export default ForgotPassword