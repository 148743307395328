import React, { useState } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import Navmenus from '../models/Navbar/Partials/Topnav'
import { MainSection } from '../models/Mainpages'
import { Extentions } from '../models/Extention'
import PasswordForm from '../models/Auth/Components/PasswordForm'
import { NewRegister } from '../models/Auth/Components/newRegister'
import { HomePage } from '../models/Home'
import { AboutUs } from '../models/About'
import { NavProfile } from '../models/NavProfile/Index'
import MyOrder from '../models/NavProfile/Partials/MyOrder'
import ForgotPassword from '../models/Auth/Components/ForgotPassword'
import ConfirmPassword from '../models/Auth/Components/ConfirmPassword'
import PaymentSuccess from '../models/Mainpages/Partials/PaymentSuccess'
import PaymentFaild from '../models/Mainpages/Partials/PaymentFaild'
import UserSignin from '../models/Auth/Components/UserSignin'
import { CustomModal } from '../components/CustomModal'
import { PrivacyPolicyMain } from '../models/Privacypolicy'

const RoutePathPages = ({ token }) => {

    const isAuthenticated = token;

    return (
        <>
        <Navmenus />                
            <Routes>
                {/* <Route path='/' element={<Navmenus />}> */}
                    <Route path='/' element={<HomePage/>} />
                    <Route path='*' element={<HomePage/>} />
                    <Route path='/aboutus' element={<AboutUs />} />
                    <Route path='/extension' element={<Extentions />} />
                    <Route path='/pricing' element={<MainSection isAuthenticated={isAuthenticated} />} />
                    <Route path='/register' element={<NewRegister />} />
                    <Route path='/forgotPassword' element={<ForgotPassword />} />
                    <Route path='/confirmPassword' element={<ConfirmPassword />} />
                    <Route path='/password' element={<PasswordForm />} />
                    <Route path='/navprofile' element={<NavProfile />} />
                    <Route path='/myorder/:id' element={<MyOrder />} />
                    <Route path='/paymentSuccess' element={<PaymentSuccess />} />
                    <Route path='/paymentFaild' element={<PaymentFaild />} />
                    <Route path='/privacypolicy' element={<PrivacyPolicyMain />} />
                {/* </Route> */}
            </Routes>
            <Outlet />
        </>
    )
}

export default RoutePathPages