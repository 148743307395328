import React from 'react'
import PricingSection from './Partials/PricingSection'
import styled from 'styled-components'
import RefundSection from './Partials/RefundSection'
// import Testimonial from './Partials/Testimonial'
import { Price } from './Partials/Price'
import Footer from '../Footer/Partials/Footer'
import { TestimonialSection } from '../Home/Partials/TestimonialSection'
import { SwiperHolder } from '../Home/Partials/Swiper'
import { Extentions } from '../Extention'

const SectionStyle = styled.div`
/* padding-top:'100px'; */
/* padding: 100px 0px 0px 0px; */
`

export const MainSection = ({ isAuthenticated }) => {
  return (
    <SectionStyle>
      <PricingSection />
      <Price isAuthenticated={isAuthenticated} />
      {/* <RefundSection /> */}
      {/* <TestimonialSection /> */}
      <SwiperHolder/>
      <Extentions/>
      <Footer />
    </SectionStyle>
  )
}
