import { Col, Row } from "antd";
import React from "react";
import { FirstCol, MainSec, Maindiv, SecondCol, Section } from "../style";
import dash from "../../../Images/home/home1.png";
import { CustomRow } from "../../../components/CustomRow";
import logo from '../../../Images/logo.png'
import BookingForm from "./BookingForm";

export const FirstSection = () => {
  return (

    <Section id="home" className="home">
      <div className="home__container">
        <div className="home__data">
          <img src={logo} alt="Hi Biller Logo" title="Hi Biller Logo" className="home__logo" />
          <h1 className="home__title">
            Manage your money like a boss
          </h1>

          <p className="home__description">
            Create beautiful invoices,
            End-to-End Billing Solution,
            make accounting easy—all in one place—with
            Hi Biller suite of money tools.
          </p>

          {/* <div className="home__buttons">
            <a href="#video" className="button">
              View Demo
            </a>
          </div> */}
          <img src={dash} alt="Hi Biller Dashboard Image" title="Hi Biller Dashboard Image" className="home__img" />
        </div>

        <div className="home__form-sec">
        <h2 className="home__title">
            Contact us for one month FREE TRIAL
          </h2>
        <BookingForm/>
        </div>

      </div>
    </Section>
  );
};
