import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Spin } from 'antd'
import Flex from '../../../components/Flex'
import { Col } from 'antd'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import { CustomCheckBox } from '../../../components/Form/CustomCheckBox'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { CustomInputPassword } from '../../../components/Form/CustomInputPassword'
import Button from '../../../components/Form/CustomButton'
import { Checkinpu, StyledLogin, StyledLoginMenu } from '../../LoginPage/Partials/style'
import { toast } from 'react-toastify'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../../utils/request'
import { selectCurrentUser } from '../authSlice'
import SigninImg from '../../../Images/signinimg.png'
import Logo from '../../../Images/logo.png'
import { LoadingOutlined } from '@ant-design/icons';



const ImgSection = styled.div`
  & img {
    width: 100%;
    height: auto;
  }
  & h2 {
    font-size: 40px;
    font-weight: 900;
    margin: 20px 0;
    color: #000;
  }
  & p {
    color: #000;
    font-size: 20px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`
const LogoImg = styled.div`
margin-top: 20px;
& img {
  width: 50px;
  border: 1px solid;
}
`

const LogoHead = styled.div`
display: flex;
justify-content: space-between;
align-items: center;


& p {
  color: #000;
font-family: 'League Spartan';
font-size: 13.044px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;

}

& span {
  color: #000;
font-family: 'League Spartan';
font-size: 13.044px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
text-decoration-line: underline;
}

`


const SignInForm = ({ HandleSignIn, handleOk, isloading , trigger }) => {

  useEffect(() => {
  form.resetFields()
  }, [trigger])



  const [check, setCheck] = useState(false)
  const token = useSelector(selectCurrentUser);
  const navigate = useNavigate();

 


  const location = useLocation();
  const { email, Password } = location.state || {};

  useEffect(() => {
    form.setFieldsValue({ email: email?.email }) || form.setFieldsValue({ email: email })
    form.setFieldsValue({ password: Password });
  }, []);








  

  const googleConfig = {
    clientId: '176349257307-8277n57npt2tie23pssv9t5g4snbnrdb.apps.googleusercontent.com',
    redirectUri: 'http://localhost:3000',
  };

  const onChange = () => {
    setCheck(!check)
  }

  const [form] = Form.useForm();

  const Navigatee = () => {
    navigate('/register')
    handleOk()
  }

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const details = jwt_decode(credentialResponse.credential);
    const email = details.email;
    const givenname = details.given_name;


    navigate('/password', {
      state: { email, givenname },
    });
    handleOk()

  };

  const onFinish = async (values) => {
    try {
      await HandleSignIn(values);
      if (!isloading) {
       handleOk()
      }
    

    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };

  const UrlsNavigate = () => {
    window.open('https://dev.hibiller.com/', '_blank');
  }

  const onFinishFailed = () => {
  }

  const ForgotPassword = () => {
    navigate('/forgotPassword')
    handleOk()
  }


  return (

    <StyledLoginMenu>
      <Form
        form={form}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <CustomRow>
          <Col span={24} md={12}>
            <ImgSection>
              <Flex spacebetween={true} >
                <div>
                  <h2>WELCOME !</h2>
                  <p>TO HI BILLER</p>
                </div>
                {/* <LogoImg>
                  <img src={Logo} />
                </LogoImg> */}
              </Flex>
              <img src={SigninImg} alt='log' title='Hi Biller Logo' />
            </ImgSection>
          </Col>

          <Col span={24} md={12} >
            <StyledLogin>
              {/* <Flex spacebetween={true} style={{ margin: '20px 0' }}>
                <img src={Logo} />
              </Flex> */}
              <LogoHead>
                <LogoImg>
                  <img src={Logo} alt='Login poster' title='Hi Biller Login poster'/>
                </LogoImg>
                <p>Not a member yet? <span onClick={Navigatee} > Create account</span></p>
              </LogoHead>

              <h2>SIGN IN</h2>
              <CustomRow space={[12, 12]}>
                <Col span={24} md={24}>
                  <h3>Email Id</h3>
                  <CustomInput placeholder={'Email ID'} type={'email'} name={'email'}
                    rules={[
                      {
                        required: true,
                        message: 'Please Enter Email ID !',
                      }
                    ]}
                  />
                </Col>
                <Col span={24} md={24}>
                  <h3>Password</h3>
                  <Checkinpu>
                    <CustomInputPassword placeholder={'Password'} type={'password'} name={'password'}
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Password !',
                        }
                      ]} />
                  </Checkinpu>
                </Col>
                <Col span={24} md={24}>
                  <p onClick={ForgotPassword}>Forgot password ?</p></Col>
                <Flex>
                  <CustomCheckBox label={'By Signing In , I Confirm To Proceed.'} onChange={onChange} checked={check} />
                </Flex>
                <Flex >
                  {/* <ButtonStandard.PrimarySeconty text={'Sign In'} htmlType={'submit'} /> */}
                  {/* <div style={{ marginTop: '10px' }}>or</div>
                  &nbsp; &nbsp;<Button.Primary text={'Sign Up'} onClick={Navigatee} /> */}
                </Flex>
                <Col span={24} md={24}>
                  {
                    isloading ?
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px', width: '60px', marginTop: '3px' }}>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 20,
                              }}
                              spin
                            />
                          }
                        />
                      </div>
                      :
                      <ButtonStandard.PrimarySeconty style={{ width: '100%', padding: '20px 0', border: '0' }} text={'Sign In'} className='GGG' htmlType={'submit'} />

                  }
                </Col>

                <Col span={24} md={24}>
                  <div style={{ textAlign: 'center' }}>or</div>
                </Col>

                <div style={{ margin: 'auto' }}>
                  <GoogleLogin
                    config={googleConfig}
                    onSuccess={handleGoogleLoginSuccess}
                    onError={(error) => {
                      // console.error('Google login error:', error);
                    }} />
                </div>
              </CustomRow>

            </StyledLogin>
          </Col>

        </CustomRow>
      </Form>
    </StyledLoginMenu>
  )
}

export default SignInForm
