import React from 'react'
import styled from 'styled-components'
import { FaQuoteLeft } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.css'

import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { testimonialDAta } from '../../../assets/data';
import user from '../../../assets/user.webp' 

export const TestimonialSection = () => {
    return (
        <Container>
            {/* <TestimonialContainer> */}
            <h2 className="title">Testimonial</h2>

            <TestimonialContainer
                slidesPerView={1}
                spaceBetween={30}
                grabCursor={true}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay,Pagination, Navigation]}
                className="mySwiper"
            >
                {
                    testimonialDAta?.map((item) => (
                        <CustomSwiperSlide key={item.key}>
                            <div className="slide">
                                <img src={user} alt="user Image" />
                                <p>{item?.content}</p>
                                <span className='quote-icon'> <FaQuoteLeft /></span>

                                {/* <div className='details'>
                                <span className="name">VIJAY</span>
                            </div> */}
                            </div>
                        </CustomSwiperSlide>
                    ))
                }
            </TestimonialContainer>
        </Container>
    )
}

const Container = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    /* gap:30px; */
    background:#e3f2fd;
    padding:5rem 0;


  .title {
    font-size: 42px;
    font-weight: 600;
    color: #020202;
  }
`

const TestimonialContainer = styled(Swiper)`
    position:relative;
    max-width:900px;
    width:100%;
    padding:50px 0;
    overflow:hidden;

    .swiper-button-prev,
    .swiper-button-next{
        width: 40px;
        height: 40px;
        border-radius:50%;
        background:rgba(0,0,0,0.1);
        transform:translateY(30px);

            @media screen and (max-width:768px) {
                display:none;
            }

        &::after,::before{
            font-size:20px;
            color:#fff;
        }
    }

    .swiper-pagination-bullet{
        background:rgba(0,0,0,0.8);

        &.swiper-pagination-bullet-active{
            background:#4070f4;
        }
    }
`

const CustomSwiperSlide = styled(SwiperSlide)`
    display:flex;
    .slide{
        display: flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        row-gap:30px;
        padding:50px 0;

        img{
            width: 170px;
            height: 170px;
            object-fit:cover;
            border-radius:50%;
        }

        p{
            padding: 0 100px;
            text-align:center;
            font-size:18px;
            font-weight:400;
            color:#333;
            @media screen and (max-width:768px) {
                /* font-size:14px; */
                padding:0 20px;
            }
        }
        span.quote-icon {
            font-size:30px;
            color:#4070f4;
        }
        .details{
            .name{
                font-size:14px;
                font-weight:600;
                color:#333;
            }
        }
    }
`