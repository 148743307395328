import React from 'react'
import ProfileTab from './Partials/MainFile'

export const NavProfile = () => {
  return (
    <div>
      <ProfileTab />
    </div>
  )
}
