import React from 'react'
import { PrivacyWrapper } from './styles'

const PrivacyPolicy = () => {
  return (
    <PrivacyWrapper>
      <h1>Privacy Policy for HiBiller Billing Software</h1>
      <p className='mainText'>At HiBiller, we are committed to protecting the privacy and security of our users'
        personal information. This Privacy Policy outlines how we collect, use, disclose,
        and protect the information collected through our billing software.</p>
      <h2 className='subText'>Information We Collect</h2>
      <h2>Personal Information</h2>

      <ul>
        <li>
          <p>When you register for an account with HiBiller, we may collect personal
            information such as your name, email address, contact number, and billing
            address.</p>
        </li>
        <li>
          <p>We may also collect payment information, including credit card details, for
            billing purposes. However, we do not store payment card information on our
            servers. Instead, we use trusted third-party payment processors to handle
            payment transactions securely.</p>
        </li>
      </ul>

      <h2>Usage Data</h2>

      <ul>
        <li>
          <p>We collect usage data, including log files, IP addresses, browser type, device
            information, and timestamps, to analyze trends, administer the software, and
            track user activity. </p>
        </li>
      </ul>
      <h2 className='subText'>How We Use Your Information</h2>
      <h2>Providing Services</h2>
      <ul>
        <li>
          <p>We use the information collected to provide and improve our billing
            software services, including processing invoices, managing accounts, and
            delivering customer support. </p>
        </li>
      </ul>
      <h2>Communication</h2>
      <ul>
        <li>
          <p>We may use your contact information to communicate with you about your
            account, billing inquiries, software updates, and promotional offers. </p>
        </li>
      </ul>
      <h2>Analytics</h2>
      <ul>
        <li>
          <p>We use usage data to analyze software usage patterns, identify areas for
            improvement, and optimize the user experience.</p>
        </li>
      </ul>
      <h2 className='subText'>Information Sharing and Disclosure</h2>
      <h2>Third-Party Service Providers</h2>
      <ul>
        <li>
          <p>We may share personal information with trusted third-party service
            providers who assist us in delivering our services, such as payment
            processors, hosting providers, and customer support platforms.</p>
        </li>
      </ul>
      <h2>Legal Compliance</h2>
      <ul>
        <li>
          <p>We may disclose personal information when required to comply with
            applicable laws, regulations, legal processes, or government requests.</p>
        </li>
      </ul>
      <h2>Business Transfers</h2>
      <ul>
        <li>
          <p>In the event of a merger, acquisition, or sale of assets, personal information
            may be transferred as part of the transaction. We will notify users of any
            such change in ownership or control of their personal information.</p>
        </li>
      </ul>
      <h2 className='subText'>Data Security</h2>
      <h2>Security Measures</h2>
      <ul>
        <li>
          <p>We implement industry-standard security measures to protect personal
            information from unauthorized access, disclosure, alteration, or destruction.</p>
        </li>
        <li>
          <p>These measures include encryption, access controls, firewalls, and regular
            security audits. </p>
        </li>
      </ul>
      <h2>Data Retention</h2>
      <ul>
        <li>
          <p>We retain personal information only for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy or as required by law. When
            personal information is no longer needed, we securely dispose of it in
            accordance with our data retention policies. </p>
        </li>
      </ul>
      <h2 className='subText'>Your Privacy Choices</h2>
      <h2>Account Settings</h2>
      <ul>
        <li>
          <p>You can update your account information and communication preferences
            by accessing your account settings within the software.</p>
        </li>
      </ul>
      <h2>Opt-Out</h2>
      <ul>
        <li>
          <p>You may opt out of receiving promotional communications from HiBiller by
            following the unsubscribe instructions provided in the communication or by
            contacting us directly.</p>
        </li>
      </ul>
      <h2 className='subText'>Changes to this Privacy Policy</h2>
      <h2>Updates</h2>
      <ul>
        <li>
          <p>We may update this Privacy Policy from time to time to reflect changes in
            our practices, legal requirements, or business operations. </p>
        </li>
        <li>
          <p>We will notify users of any material changes to this Privacy Policy by
            posting the updated version on our website or notifying users via email. </p>
        </li>
      </ul>
      <h2 className='subText'>Contact Us</h2>
      <p>If you have any questions, concerns, or feedback about this Privacy Policy or our
        privacy practices, please contact us at <span>Emailid:</span> <a href="mailto:hr@ideaux.in">hr@ideaux.in</a></p>
      <p><span>Address:</span> 309, Eden Brook, Rajakamangalam Road, Ramanputhur, Nagercoil, Kanyakumari Dist-629002.</p>


    </PrivacyWrapper>
  )
}

export default PrivacyPolicy
