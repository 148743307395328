import styled from "styled-components";
import { THEME } from "../../../theme";

export const AboutImgHolder = styled.div`
    /* margin:60px 0; */
    /* padding: 60px 0; */
    position:relative;
    height: 230px;
    width:100%;

    & img{
        position:absolute;
        inset:0;
        width:100%;
        height:230px;
    }

    & div{
        background: rgba(0, 0, 0, 0.31);
        position:absolute;
        inset:0;
        z-index:10;
        display:flex;
        align-items:flex-start;
        flex-direction:column;
        justify-content:center;
        row-gap:26px;

        & h3{
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin-left:80px;
        }

        & h4{
            margin-left:80px;
            color: #FFF;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }
`

export const AboutTitle = styled.div`
    width:100%; 

    & h1{
        display:inline-block;
        position:relative;
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &::before {
            content: ""; /* Add content property to make the pseudo-element render */
            height: 4px;
            width: 100%;
            position: absolute;
            bottom: -14px; /* Added 'px' unit */
            border-radius: 10px;
            background: linear-gradient(90deg, #4E4584 0%, #2E8D93 100%);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        }
    }
    margin-bottom:86px;
    text-align:center;
`

export const ContentImg = styled.div`
    margin:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    /* background:red; */
    max-width: 532px;

    & img{
        border-radius: 5px;
        border: 7px solid #279E96;
        height:480px;
        width:100%;
        /* height:100%; */
    }
`

export const ContentHolder = styled.div`
    border-radius: 50px 0px;
    background: #FFF;
    padding:25px;
    height:100%;
    display:flex;
    justify-content:space-between;
    flex-direction:column;

    & p{
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`

export const LaunchHolder = styled.div`
    margin:80px 0;
    position:relative;
    background:#fff;
    overflow:hidden;
    
    & div:nth-child(1){
        padding:40px 0;
    }

`

export const FirstWave = styled.img`
    position:absolute;
   left:0;
   top:0;
   height:100%;
   /* width:100%; */
`

export const SecondWave = styled.img`
    position:absolute;
    right:0;
   /* height:100%; */
    top:0;
`

export const LanchBtn = styled.span`
    border-radius: 26.5px;
    background: rgba(44, 148, 112, 0.90);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: #FFF;
    font-size: 16.867px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding:3px 12px;
`

export const LanchTitleHolder = styled.div`
    position:relative;
    margin:16px 0;

    &::before{
        content: "";
        position:absolute;
        height:80%;
        width:3px;
        background:#000;
        left:0;
        top:50%;
        transform:translateY(-50%);
        border-radius:3px;
    }

    & h1{
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-left:14px;
    }
`

export const LanchContentHolder = styled.div`
    & p{
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
`

export const LanchImgHolder = styled.div`
    margin:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    max-width: 600px;

    & img{
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        border: 20px solid rgba(44, 148, 112, 0.68);
        height:280px;
        width:100%;
    }
`

//  ABOUT US -------------->
export const AimCardHolder = styled.div`
    width:100%;
    padding:30px;
    margin-top:20px;
    display:flex;
    position:relative;
    justify-content:center;
    opacity:0;
    
    @media ${THEME.MOBILEL} {
        justify-content:${props => props.order === 1 ? 'flex-start' : 'flex-end'};
    }
`

export const AimCardRow = styled.div`
    width:285px;
    height:100%;
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    gap:22px;
`
export const AimTilte = styled.h1`
  color:#fff;
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
`
export const AimContent = styled.h2`
  color: #FAFAFA;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
  letter-spacing: 0.56px;
`
export const FactCardRow = styled.div`
    width:600px;
    height:100%;
    display:flex;
    align-items:flex-start;
    flex-direction:column;
    gap:22px;
`

export const FactContent = styled.h2`
font-family: 'Poppins', sans-serif;
  color:#fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; 
  letter-spacing: 0.56px;
`
export const OneStartUp = styled.div`
    & h1 {
        color: #000;
        font-family: 'Raleway', sans-serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px; /* 125% */
        letter-spacing: 2.24px;

        @media (max-width: 768px) {
            font-size: 40px;
            line-height: 60px; /* 125% */
        }
        @media (max-width: 580px) {
            font-size: 26px;
            line-height: 40px;
        }
    }
    & span {
        color: #0362D7;
        font-family: 'Raleway', sans-serif;
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px;
        letter-spacing: 2.24px;

        @media (max-width: 768px) {
            font-size: 40px;
            line-height: 60px;
        }

        @media (max-width: 580px) {
            font-size: 26px;
            line-height: 40px;
        }
    }
`

export const VideoHolder = styled.div`
    display:flex;
      align-items:center;
      justify-content:center;
      overflow:hidden;
      border-radius: 12px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      position:relative;
      width:100%;
      
  & video{
      width:100%;
      height:100%;
      vertical-align:middle;
      display:inline-block;
      object-fit:cover;
  }
`

export const StartUpContent = styled.div`
    color: #696969;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; 
    letter-spacing: 0.56px;

    @media (max-width: 768px) {
    display: block;
    }
`

export const Black = styled.div`
    position: relative;
    margin-top: 30px;
    height:100%;
    background: #101010;
    padding:20px;
    overflow:hidden;

    & span.sticky{
    position:fixed;
    right:-50%;
    bottom:20%;
    transform:rotate(90deg);
    color: #616161;
    text-align: right;
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 166.667% */
    letter-spacing: 1px;
    text-transform: capitalize;
    transition:0.5s;
    &.active{
        right:0%;
    }
    }
`

export const SvgHolder = styled.div`
  position:absolute;
  left:55%;
  top:10%;
  transform:translateX(-50%);
  display:none;

  @media ${THEME.MOBILEL} {
    display:block;
  }

  &::before{
    width:8px;
    height: 8px;
    background:#fff;
    border-radius:8px;
    z-index:5;
    position:absolute;
    content:'';
    right:-3px;
    top:0;
  }
  &::after{
    width:8px;
    height: 8px;
    background:#fff;
    border-radius:8px;
    z-index:5;
    position:absolute;
    content:'';
    right:23px;
    bottom:0;
  }
  & #rocket {
    position:absolute;
    top: -25%;
    right: -8%;
    transform:rotate(45deg)
  }
`
export const Aim = styled.h1`
    margin-top: 120px;
    color: #FFF;
    font-family: 'Raleway', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; 
    letter-spacing: 1.68px;

    @media (max-width: 768px) {
    margin-top: 24px;
    font-size: 32px;
    }
`

export const Facts = styled.h1`
    margin-top: 100px;
    color: #FFF;
    font-family: 'Raleway', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; 
    letter-spacing: 1.68px;

    @media (max-width: 768px) {
    margin-top: 24px;
    font-size: 32px;
    line-height: 12px; 
    }
`


export const Spanone = styled.span`
    color: #0362D7;
    font-family: 'Raleway', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 1.68px;

    @media (max-width: 768px) {
    margin-top: 24px;
    font-size: 32px;
    }
`