import React, { useEffect } from 'react'
import { Fragment } from 'react'
import styled from 'styled-components'
import CheckImg from '../../../Images/Check.png'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Footer/Partials/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { removeSubScription, selectCurrentSubScription } from '../subScriptionSlice'
import request from '../../../utils/request'

const SuccessCard = styled.div`
    width: 500px;
    margin: auto;
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 10px 0px #00000024;
    border-radius: 10px;

    & img {
        width: 80px;
        margin: auto;
    }
    & h2 {
        color: #04D758;
        margin: 20px 0;
        font-size: 25px;
    }
    & p {
        margin: 20px 0;
        padding: 20px;
    }
    .GGG {
        background: #5F2DED !important;
    }
    @media screen and (max-width: 800px) {
        width: 90%;
        margin: 20px;
    }
`

const PaymentSuccess = () => {

    const nevigate = useNavigate();
    const dispatch = useDispatch();
    const subscription = useSelector(selectCurrentSubScription);

    useEffect(() => {
    // const PostSubScription = async () => {
    //     await request.post('gate_way/success_plans_details/', subscription)
    //         .then(function (response) {
    //             console.log(response.data, 'data params');
    //         }).catch(error => {
    //             console.log(error, 'faildddddddd');
    //         })
    // }
        
    PostSubScription();
    }, [])

    const PostSubScription = async () => {
        await request.post('gate_way/success_plans_details/', subscription)
            .then(function (response) {
                dispatch(removeSubScription());
            }).catch(error => {
                console.log(error, 'faildddddddd');
            })
    }

    const Navigate = () => {
        nevigate('/pricing')
    }
    return (
        <Fragment>
            <SuccessCard>
                <img src={CheckImg} />
                <h2>PAYMENT SUCCESSFUL</h2>
                <p>Your Subscription has been Successfully activated, enjoy your billing system</p>
                <div onClick={Navigate}>
                    <ButtonStandard.PrimarySeconty style={{ width: '100%', padding: '20px 0', border: '0' }} text={'OK'} className='GGG' />
                </div>
            </SuccessCard>
            <Footer />
        </Fragment>
    )
}

export default PaymentSuccess