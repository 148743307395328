import React, { Fragment } from 'react'
import { AboutHeader } from './Partials/AboutHeader'
import { AboutContent } from './Partials/AboutContent'
import { ThirdSection } from '../Home/Partials/ThirdSection'
import Footer from '../Footer/Partials/Footer'
import { NewLaunch } from './Partials/NewLaunch'
import { SixthSection } from '../Home/Partials/SixthSection'
import { FourthSection } from '../Home/Partials/FourthSection'
import { FirstSection } from '../Home/Partials/FirstSection'
import { FifthSection } from '../Home/Partials/FifthSection'
import VideoSection from '../Home/Partials/VideoSection'

export const AboutUs = ({ handleSignIn }) => {
    return (
        <Fragment>
            <FirstSection />
            <ThirdSection />
            <FourthSection />
            <FifthSection />
            <VideoSection handleSignIn={handleSignIn} />
            <SixthSection />
            <Footer />
        </Fragment>
    )
}
