import React, { useState } from 'react'
import Flex from '../../../components/Flex'
import Button from '../../../components/Form/CustomButton'
import { Col, Form } from 'antd'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import request from '../../../utils/request'
import { useEffect } from 'react'

const FormStyleAntd = styled.div`
 .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
    width: 100% !important;
    background-color: rgb(255 255 255);
    border: 1px dashed #bababa;
 }

`

const Profile = () => {

    const [form] = Form.useForm()

    const POST_URL = ''

    const [datasSet, setDatasSet] =  useState({})

    const onFinish = (values) => {
        // AddSign(values)
    }

    const onFinishFailed = (errorInfo) => {
        console.error("Added Failed")
    };

    const onReset = () => {
        form.resetFields();
    }

    useEffect(() => {
        AddSign()
    }, [])

    useEffect(() => {
        form.setFieldsValue(datasSet)
    }, [datasSet])


    const AddSign = () => {
        request.get('user/user_get_detail/')
            .then(function (response) {
                setDatasSet(response.data)
            })
            .catch(function (error) {
                console.log(error, 'faild');
            });
    }

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            style={{ background: '#fff', padding: '20px' }}>
            <FormStyleAntd>

                <CustomRow space={[24, 24]}>
                    <Col span={24} md={15}>
                        <CustomInput label={'Name'} disabled={true} name={'name'} placeholder={'Enter Customer Name'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Name!',
                                }
                            ]} />
                    </Col>
                    <Col span={24} md={15}>
                        <CustomInput type={'email'} disabled={true} label={'Email'} placeholder={"Enter Your Mail ID"} name={'email'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter email',
                                },
                            ]} />
                    </Col>
                    <Col span={24} md={15}>
                        <CustomInput label="Contact Number" disabled={true} InputLabelProps={{ shrink: true }}
                            maxLength={10}
                            minLength={10}
                            placeholder={"Contact Number"}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            name="mobile_number" rules={[
                                {
                                    required: true,
                                    message: 'Please Fill Details!'
                                },
                            ]} />

                    </Col>
                </CustomRow>

            </FormStyleAntd>
            {/* <Flex end={'true'} gap={'20px'} margin={'20px 0px'}>
                <Button.Primary text={'submit'} htmlType={'submit'} />
                <Button.Danger text={'Cancel'} onClick={() => onReset()} />
            </Flex> */}
        </Form>
    )
}

export default Profile