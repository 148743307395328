import React, { Fragment } from 'react'
import Footer from '../Footer/Partials/Footer'
import PrivacyPolicy from './Partials/PrivacyPolicy'

export const PrivacyPolicyMain = () => {
    return (
        <Fragment>
            <PrivacyPolicy />
            <Footer />
        </Fragment>
    )
}

