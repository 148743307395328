import { Carousel } from "antd";
import styled from "styled-components";
import bgtestimonial from '../../ImgIcons/image/testimonialBgImg.png'
import { THEME } from "../../theme";


export const PriceTextSecnt = styled.div`
max-width:1150px;
margin:auto;
& h1 {
    margin-top: 100px;
    font-size:50px;
    font-family: 'Gabarito', cursive;
    font-weight: 700 !important;
    color: ${THEME.app_primary};
}
& h3 {
    font-weight: 700 !important;
    font-size:16px;
    /* color: #25b824; */
}
& h2 {
    font-weight: 700 !important;
    font-size:20px;
}

& .ColumSections {
    margin-left: -20px !important;
    margin-right: -20px !important;
    background: purple;
}

.priceBoxstyle {
    margin: 50px;
}

@media screen and (max-width: 500px) {
    & h1 {
    font-size:30px;
    & span {
        font-size:30px;
    }
}
& h2 {
    font-size:15px;
   }
   .priceBoxstyle {
    margin: 20px;
}
}
`
export const CardSection = styled.div`
border-radius: 20px;
border: 1px solid #0e3a57;
padding: 20px;
margin: auto;
width: 100%;
padding: 50px 20;
/* display: flex; */
/* align-items: center; */
/* margin: 30px 40px; */
justify-content: space-around;
background:#cad4d9;

@media only screen and (max-width: 600px) {
    display: flow-root;
    width: 100%;
}

& h5 {
    color: #0e3a57;
    font-size: 22px;
    font-weight: 800;
    font-family: 'Gabarito', cursive;
    margin-bottom: 10px;
}
& p{
    color:#0e3a57;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Gabarito', cursive;
}
`


// Box section 

export const BoxSection = styled.div`
margin: 50px 60px;
& h3 {
font-size: 17px;
font-family: 'Gabarito', cursive;
font-weight: 700 !important;
color: #000;
}
.heading {
    font-size: 20px;
    font-family: 'Gabarito', cursive;
    font-weight: 700 !important;
    color: #a1a1a2;
}

@media only screen and (max-width: 600px) {
    margin: 30px 0px;
}

`

export const TotalAmtBox = styled.div`
margin: 30px 0px;
border-radius: 20px;
border: 1px solid #c3b1b1b8;
padding: 50px;
& h5 {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Gabarito', cursive;
    margin-bottom: 10px;
}

.RateStyle {
    font-size: 18px;
    font-weight: 700;
    color: #64748b;
    font-family: 'Gabarito', cursive;
    & span {
        font-size: 15px;
        color: #64748b;
        margin-right:4px;
        font-weight: 900;
    }
}
.RateStyltext {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Gabarito', cursive;
}

@media only screen and (max-width: 600px) {
    padding: 20px 10px;
}

`
// =========== RefundSection ==========


export const RefundPay = styled.div`
/* padding: 40px 0; */
background: linear-gradient(270deg, #3C91E7 0%, #002459 126.93%);
color: #fff;
.LeftBoxstly {
    /* background-color: #fff; */
}
padding: 50px 30px;
img {
    width: 60%;
}
& h2 {
margin-bottom: 20px;
font-size: 20px;
font-weight: 600;
}
.liststyle  {
    margin: 25px 0;
    ul {
        list-style-type: none;
        padding: 32px;
        width: 65%;
        position: relative;
    }
    ul li {
        padding: 6px 0;
        margin-left: 20px;
    }
    ul li svg {
        margin-right: 15px;
        margin-top: 10px;
    }
    @media only screen and (max-width: 1000px){
        ul {
        width: 100% !important;
    }
    }
}
.RefundPaydetails {
    /* display: flex; */
    /* align-items: center; */
}
`



// ====== Testimonial =======

export const TestimonialSection = styled.div`
padding: 40px 0;
background-color: #a2a1a138;
background-image: url(${bgtestimonial});
background-repeat: no-repeat;
background-position: left bottom;
/* background-attachment: fixed; */
background-size: contain;
& h5 {
    color: #000;
    font-size: 20px;
    font-weight: 800;
    font-family: 'Gabarito', cursive;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
& h2 {
    color: #000;
    font-size: 25px;
    font-weight: 800;
    font-family: 'Gabarito', cursive;
    display: flex;
    justify-content: center;
    & span {
        color: #000;
        font-size: 25px;
        font-weight: 800;
        font-family: 'Gabarito', cursive;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
        border-bottom: 2px solid #2068b0;
    }
}
`

export const PreviousButton = styled.button`
position: absolute;
left: 44%;
z-index: 99;
width: 50px;
height: 30px;
width:30px;
background: transparent;
border: none;
/* background-color: #000; */
background: linear-gradient(180deg, #007FFF 0%, #0051A2 100%);
border-radius: 50%;
margin: 20px;
& svg {
    color: #fff;
    font-size: 15px;
    margin: 7px;
    font-family: 'Gabarito', cursive;
    font-weight: 900 !important;
}
@media only screen and (max-width: 900px) {
    left: 34%;
}
`;

export const NextButton = styled.button`
position: absolute;
z-index: 99;
right: 44%;
height: 30px;
width:30px;
background: transparent;
border: none;
background: linear-gradient(180deg, #007FFF 0%, #0051A2 100%);
border-radius: 50%;
margin: 20px;
& svg {
    color: #fff;
    font-size: 15px;
    margin: 7px;
    font-family: 'Gabarito', cursive;
    font-weight: 900 !important;
}

@media only screen and (max-width: 900px) {
    right: 34%;
}
`;

export const CarouselCards = styled.div`
position:relative;
padding: 30px 10px;
/* background: white; */
.TestimTopIcon {
    background: linear-gradient(180deg, #007FFF 0%, #0051A2 100%);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 18px;
    width: 37px;
    height: 36px;
    & svg {
       width: 25px;
       margin: 2px 5px;
    }
}
`

export const Carouselslider = styled(Carousel)`
  height: 100%;
  width: 100%;
  position: relative;
  
`;