import { PricingSvgs } from "../../../assets/PricingSvg"

export const SubDAta = [
    {
        "planing_choice": "Billing",
        "key": 1,
        "option": [
            {
                "planing_domain": "webApp",
                "key": 1,
                "plan": [
                    {
                        "key": 1,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 2,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 3,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            },
            {
                "planing_domain": "Desktop",
                "key": 2,
                "plan": [
                    {
                        "key": 3,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 4,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 5,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            },
            {
                "planing_domain": "Mobile",
                "key": 3,
                "plan": [
                    {
                        "key": 6,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 7,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 8,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            }
        ]
    },
    {
        "planing_choice": "E- Commerce",
        "key": 2,
        "option": [
            {
                "planing_domain": "webApp",
                "key": 4,
                "plan": [
                    {
                        "key": 10,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 11,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 12,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            },
            {
                "planing_domain": "Mobile",
                "key": 5,
                "plan": [
                    {
                        "key": 13,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 14,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 15,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            }
        ]
    },
    {
        "planing_choice": "others",
        "key": 3,
        "option": [
            {
                "planing_domain": "SMS",
                "key": 6,
                "plan": [
                    {
                        "key": 16,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 17,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 18,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            },
            {
                "planing_domain": "whatapp",
                "key": 7,
                "plan": [
                    {
                        "key": 19,
                        "year": "3 Month",
                        "amount": 100
                    },
                    {
                        "key": 20,
                        "year": "6 Month",
                        "amount": 300
                    },
                    {
                        "key": 21,
                        "year": "9 Month",
                        "amount": 600
                    }
                ]
            }
        ]
    },
]

export const proDomain = [
    {
        "name": "MOBILE",
        "logo": PricingSvgs?.Mobile,
        "bgcolor": '#b9ff8d5e',
    },
    {
        "name": "WEBAPP",
        "logo": PricingSvgs?.Web,
        "bgcolor": '#3e74ff5e',
    },
    {
        "name": "DESKTOP",
        "logo": PricingSvgs?.Desktop,
        "bgcolor": '#ff41415e',
    },
]

export const proServices = [
    {
        "name": "SMS",
        "logo": PricingSvgs?.SMS,
        "bgcolor": '#ffb9865e',
    },
    {
        "name": "WHATSAPP",
        "logo": PricingSvgs?.WhatApp,
        "bgcolor": '#8dc8ff5e',
    },
    {
        "name": "PAYMENTGATEWAY",
        "logo": PricingSvgs?.Payment,
        "bgcolor": '#ff8de65e',
    },
]
