import React, { useEffect } from 'react'
import { Fragment } from 'react'
import styled from 'styled-components'
import FaildImg from '../../../Images/Faild.png'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Footer/Partials/Footer'
import { useDispatch } from 'react-redux'
import { removeSubScription } from '../subScriptionSlice'

const SuccessCard = styled.div`
    width: 500px;
    margin: auto;
    padding: 40px 20px;
    background-color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 10px 0px #00000024;
    border-radius: 10px;

    & img {
        width: 80px;
        margin: auto;
    }
    & h2 {
        color: #D70000;
        margin: 20px 0;
        font-size: 25px;
    }
    & p {
        margin: 20px 0;
        padding: 20px;
    }
    .GGG {
        background: #5F2DED !important;
    }
    @media screen and (max-width: 800px) {
        width: 90%;
        margin: 20px;
    }
`

const PaymentFaild = () => {

    const nevigate = useNavigate()

    const dispatch =useDispatch();

    useEffect(() => {
        dispatch(removeSubScription())
    }, [])
    
    const Navigate = () => {
        nevigate('/pricing')
    }
    return (
        <Fragment>
            <SuccessCard>
                <img src={FaildImg} />
                <h2>PAYMENT FAILED</h2>
                <p>Something went wrong! please try again!</p>
                <div onClick={Navigate}>
                    <ButtonStandard.PrimarySeconty style={{ width: '100%', padding: '20px 0', border: '0' }} text={'OK'} className='GGG' />
                </div>
            </SuccessCard>
            <Footer />
        </Fragment>
    )
}

export default PaymentFaild