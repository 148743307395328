import { combineReducers } from "redux"
import authReducer from '../models/Auth/authSlice'
import emailReducer from '../models/Auth/googleSlice'
import userReducer from '../models/Auth/googleSlice'
import orderSlice from '../models/NavProfile/OrderSlice'
import SubscriptionReducer from "../models/Mainpages/subScriptionSlice"
import BookingReducer from "../models/Home/BookingSlice"

const rootReducer = combineReducers({
    auth: authReducer,
    email: emailReducer,
    user: userReducer,
    orders: orderSlice,
    sub:SubscriptionReducer,
    booking: BookingReducer,
})

export default rootReducer;