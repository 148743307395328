// import { createSlice } from "@reduxjs/toolkit";
// const initialState = {
//     token: null,
//     authUser: null
// }
// const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         setCredentials: (state, action) => {
//             console.log(action.payload,'wwk');
//             const { username, jwt } = action.payload
//             state.authUser = username
//             state.token = jwt
//         },
//         logOut: (state, action) => {
//             state.authUser = null
//             state.token = null
//         }
//     }
// })
// export const { setCredentials, logOut } = authSlice.actions
// export const selectCurrentUser = (state) => state.auth.authUser
// export const selectCurrentToken = (state) => state.auth.token
// export default authSlice.reducer

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    jwt: null,
    username: null,
    email:null,
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { username, jwt,email } = action.payload
            state.username = username
            state.jwt = jwt
            state.email = email
        },
        logOut: (state, action) => {
            state.username = null
            state.jwt = null
            state.email = null
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions
export const selectCurrentUser = (state) => state.auth.username
export const selectCurrentJwt = (state) => state.auth.jwt
export const selectCurrentEmail = (state) => state.auth.email
export default authSlice.reducer