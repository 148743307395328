import React from "react";
import { ClientSection, SectionTitle } from "../style";
import { IoMdCheckmark } from "react-icons/io";
import { clientData, clienttitle } from "../../../assets/data";

export const SixthSection = () => {
  return (
    <ClientSection id="client">
      <div className="client__container">

        <SectionTitle>Stop chasing clients around</SectionTitle>

        <div className="client__row">
          {
            clienttitle?.map((item)=>(
              <h6 key={item.key}> <IoMdCheckmark />{item.title}</h6>
            ))
          }
        </div>

        <div className="content__holder">
          {
            clientData.map((item)=>(
              <div className="content__erapper" key={item.key}>
                <span>{item.icon}</span>
                <h4>{item.title}</h4>
                <p>{item.content}</p>
              </div>
            ))
          }
        </div>
      </div>
    </ClientSection>
  );
};
