const GETSUBCRIPTIONDETAILS = 'subscription/plan_detail/';
const POSTSUBCRIPTIONDETAILS = 'subscription/user_subscription/';
const GETDURATION = 'subscription/plan_detail_duration/';
const POSTDURATIONVALUE = 'subscription/user_subscription_duration/';
const POSTDEMOREQ = 'send/project'

export const APIURLS = {
    GETSUBDETAILS: GETSUBCRIPTIONDETAILS,
    POSTSUBDETAILS: POSTSUBCRIPTIONDETAILS,
    GETDURATIONURL: GETDURATION,
    POSTDURATIONDETAILS: POSTDURATIONVALUE,
    POSTDEMOREQ:POSTDEMOREQ,
}