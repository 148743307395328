
import { NavLink } from 'react-router-dom';
import styled from 'styled-components'

export const TopNavBar = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  padding: 5px;
  color: #262626;
  position:sticky;
  top:0px;
  height: 70px;
  width: 100%;
  z-index: 999;
  transition: 1s ease-out !important ;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  a:-webkit-any-link {
    cursor: pointer;
    text-decoration: none;
    color: #545454;
    font-size: 16px;
    padding: 0 0 5px 0;
    /* border-bottom: 1px solid #545454 !important; */
}

a:-webkit-any-link:active {
    color: #0391df;
    /* padding: 0 0 5px 0; */
    border-bottom: 1px solid #545454 !important;
}
a:-webkit-any-link:hover {
  color: #0391df;
    /* border-bottom: 1px solid #545454 !important; */
}
  &.sticky {
    position: fixed;
    transition: all 1s ease-out !important;
  }

  & button {
    background-color: #d6a461;
    color: var(--white-color);
    font-weight: 400;
    font-size:1rem;
    border-radius: 25px;
    padding: 20px 30px;
    justify-content: center;
    display: flex;
    align-items: center;
    float: left;
    margin-right:20px;
    &:hover {
        background-color: #0980bc !important;
    }
  }

  .btnstylesmoble {
    & svg {}
  }

  @media screen and (max-width: 992px) {
    .btnstylesmoble {
      display: none;
  }
  }
  @media screen and (min-width: 992px) and (max-width: 2000px) {
    .MediaScreenshow {
      display: none;
  }
  }

 
`;
export const LogoPlace = styled(NavLink)`
  display: flex;
  align-items: center;
  /* padding-left: 50px; */
  cursor: pointer;
  & img{
    
    width: 150px;
  }
  /* & img {
    width: 35vh;
    height: 21vh;
    top: 0;
    position: absolute;
    z-index:9999;
  }
  & h2 {
    color: #fff;
    font-size: 25px;
    background-color: #000;
    padding: 5px 20px;
  }
  @media screen and (max-width:992px) {
    padding-left: 10px;
    & img {
    width: 23vh;
    height: 14vh;
  }
  } */
`;
export const NavCollapse = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-right: 90px;
  gap: 40px;

  & span {
    color: var(--white-color);
    text-decoration: none;
    font-weight: 400;
    padding: 10px 2px;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      color: var(--brown-color);
      /* background: #252525; */
    }
    @media (max-width: 992px){
        color: #000;
    }
   
  }
  @media screen and (min-width: 1207px) and (max-width: 2900px){
        & span { padding: 10px 16px;}
    }
  @media screen and (max-width:992px) {
    position: absolute;
    display: ${(props) => props.State};
    flex-direction: column;
    padding:20px;
    gap: 0;
    width: 100%;
    top: 70px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    right: 0;
    background-color: #f3f8ff;

    & a {
      display: block;
      /* text-align: center; */
      font-size:20px;
      border-radius: 0;
      line-height: 2rem;
    }
  }
`;
export const Iconplace = styled.div`
  /* padding-right: 50px; */
  display: none;

  & svg {
    color: var(--white-color);
    font-size: 2rem;
    cursor: pointer;
  }

  @media screen and (max-width:992px) {
    display: flex;
  }
`;

// export const Button = styled.button`
// & .ant-btn-primary {
//     background-color: red;
// }

//   & .ant-btn-default
//    {
//     background-color: red;
//    }
//    & a {
//     background-color: red;
//    }
// `;
export const ModalBody = styled.div`
      padding: 60px 40px 40px !important;
    background-color: rgba(255, 134, 116, 0.20);
    background-size: cover;
    background-position: center;
    color: #572c5f;
    background-attachment: fixed; 
    
    & ::placeholder {
font-size: 17px;
font-weight: 600;
font-family: var(--font-family3);
color:#adaec1;

}

& .ant-btn {
  color: #fff;
    background-color: #572c5f;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 38px;
    width: 100%;
    height: 40px;
    padding: 0;
    border: none;
    border-radius: 5px;
    border: none;
    display: inline-block;
    transition: all 0.6s ease 0s;
}
`;


export const Heading = styled.div`
color: #572c5f;
text-align: center;
font-size: 33px;
font-weight: 700;
letter-spacing: 1px;
margin: -5px 0 10px;
font-family: 'Cormorant Garamond', serif;

`;


export const NavProfile = styled.div`
/* background-color: #28aaeb; */
padding: 10px 20px;
margin: 0 10px ;
color: #28aaeb;
border-radius: 18px;
cursor: pointer;
/* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
& svg {
  font-size: 20px;
}
`

