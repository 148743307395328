import React from "react";
import { CustomRow } from "../../../components/CustomRow";
import { Col } from "antd";
import { CopyRightFooter, CopyWrightHolder, FooterSectionStyle, NavFooterLink } from "./StyleFooter";
import { BiLogoFacebook } from "react-icons/bi";
import { BiLogoInstagram } from "react-icons/bi";
import { BiLogoLinkedin } from "react-icons/bi";
import Logo from '../../../assets/Logo.png'
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <FooterSectionStyle>
        <CustomRow space={[24, 24]}>
          <Col span={12} sm={12} >
            <div style={{ background: '#fff', display: 'inline-block', padding: '5px 10px', borderRadius: '5px', boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.25)', marginBottom: '20px' }}>
              <img src={Logo} alt="Footer logo" title="Hi Biller Footer logo" style={{ width: '120px' }} />
            </div>
            <p>
              Supercharge
              <br />
              your growing business
              <br />
              from your software
              <br />
            </p>
          </Col>
          {/* <Col span={24} sm={12} lg={8}>
            <h3>Product</h3>
            <p>Gst billing</p>
            <p>Ecommerce</p>
            <p>Sms</p>
            <p>Payment Gateway</p>
          </Col> */}
          <Col span={24} sm={12} >
            <h3>Company</h3>
            <NavFooterLink>
              <a href="aboutus">About</a>
              <a href="pricing">Pricing</a>
              <a href="privacypolicy">Privacy Policy</a>
            </NavFooterLink>
            {/* <a href="">Privacy Policy</a> */}
            {/* <a href="">Support Policy</a> */}
          </Col>
          {/* <Col span={24} md={12} lg={6}>
            <h3>Resources</h3>
            <p>Terms of Service</p>
            <p>FAQ</p>
            <p>Affiliates</p>
          </Col> */}
        </CustomRow>
      </FooterSectionStyle>
      <CopyRightFooter>
        <CustomRow space={[24, 24]}>
          <Col lg={18} md={16} sm={24}>
            <CopyWrightHolder>Copyright 2024 HI-BILLER all rights reserved. Powered by : <a href="https://www.ideaux.in/" target="_blank">IDEAUXTECH</a></CopyWrightHolder>
          </Col>
          <Col className="footermedia" lg={5} md={5} sm={24}>
            <a href="https://www.instagram.com/hibiller/"><BiLogoInstagram color="white" size={24} /></a>
            <a href="https://www.facebook.com/profile.php?id=61557311520279"><BiLogoFacebook color="white" size={24} /></a>
            <a href="https://www.linkedin.com/company/100918759/admin/feed/posts/"><BiLogoLinkedin color="white" size={24} /></a>
          </Col>
        </CustomRow>
      </CopyRightFooter>
    </div>
  );
};

export default Footer;
