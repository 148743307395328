import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import RoutePathPages from './routes/routePath';
import GlobalStyle from './theme/GlobalStyle';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentJwt } from './models/Auth/authSlice';
import { ToastContainer } from 'react-toastify';

function App() {

  const location = useLocation()

  const token = useSelector(selectCurrentJwt);

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])


  return (
    <div>
      <GlobalStyle />
      <RoutePathPages token={token} />
      <ToastContainer />
    </div>
  );
}

export default App;
