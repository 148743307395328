import ActiveTick from './Active.svg'
import Mobile from './Mobile.svg'
import Web from './Web.svg'
import WhatApp from './WhatsApp.svg'
import Desktop from './Desktop.svg'
import SMS from './SMS.svg'
import Payment from './Payment.svg'

import Yes from './Yes.svg'
import Close from './Close.svg'


export const PricingSvgs = {
    ActiveTick,
    Mobile,
    Web,
    WhatApp,
    Desktop,
    SMS,
    Payment,
    Yes,
    Close
}