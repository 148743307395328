import React, { Fragment, useRef } from 'react'
import { Object, proServices } from './Data'
import { CustomSelect } from '../../../components/Form/CustomSelect'
import { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { BtnHolder, BuyHolderCard, BuyHolderSubTitle, BuyHolderTitle, PriceingSelectTitle, PriceingTitle, PricingCard, PricingRow } from './Style';
import { CustomInput } from '../../../components/Form/CustomInput';
import { useEffect } from 'react';
import { CustomCheckBox } from '../../../components/Form/CustomCheckBox';
import Flex from '../../../components/Flex';
import { CustomRow } from '../../../components/CustomRow';
import { Container } from '../../../assets/CommonStyle';
import { PricingSvgs } from '../../../assets/PricingSvg';
import request, { BASE_URL, baseRequest } from '../../../utils/request';
import { proDomain } from './Data';
import { toast } from 'react-toastify';
import { APIURLS } from '../../../utils/ApiUrls';
import { selectCurrentUser } from '../../Auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../../../components/CustomModal';
import UserSignin from '../../Auth/Components/UserSignin';
import Button from '../../../components/Form/CustomButton';
import { setSubScription } from '../subScriptionSlice';

export const Price = ({ isAuthenticated }) => {
    // console.log(isAuthenticated,'5isAuthenticated');

    // const nevigate = useNavigate()

    const [billingData, setBillingData] = useState([]);
    const [eCommerceData, setECommerceData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [durationData, setDurationData] = useState([]);

    const [fetchedData, setFetchedData] = useState([])


    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [modalwidth, setModalWidth] = useState(0)

    const [selectedDuration, setSelectedDuration] = useState([]);
    const [selectedBilling, setSelectedBilling] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState([]);
    const [selectedService, setSelectedService] = useState([]);

    // const [totalAmount, setTotalAmount] = useState(0)

    const [billingChecked, setBillingChecked] = useState(false)
    const [eCommerceChecked, setECommerceChecked] = useState(false)
    const [serviceChecked, setServiceChecked] = useState(false)

    // ========= Login status =======

    const loginData = useSelector(selectCurrentUser)

    // ==========  Payment 

    const [encRequest, setEncRequest] = useState("");
    const [accessCode, setAccessCode] = useState("");

    const [form] = Form.useForm()

    const formRef = useRef(null);
    const dispatch = useDispatch();
    // ====== modal states ==

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        // Fetch data from backend and update state
        fetchData();
        DurationData();
    }, []);

    // ======== Signin Modal ==

    const handleSignIn = () => {
        setModalContent(<UserSignin handleOk={handleOk} />);
        setModalTitle("");
        setModalWidth(900)
        showModal();
    };

    const fetchData = () => {
        baseRequest.get(APIURLS.GETSUBDETAILS)
            .then(function (response) {
                setFetchedData(response.data)
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    };

    const DurationData = () => {
        baseRequest.get(APIURLS.GETDURATIONURL)
            .then(function (response) {
                setDurationData(response.data.duration)
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    };

    useEffect(() => {
        if (fetchedData) {
            const billingInfo = fetchedData.gst_billing;
            const EcommerceInfo = fetchedData.ecommerce;
            const serviceInfo = fetchedData.service;

            if (billingInfo && billingInfo.length > 0) {
                setBillingData(billingInfo);
                // setSelectedBilling([billingInfo[0]]);
            }
            if (EcommerceInfo && EcommerceInfo.length > 0) {
                setECommerceData(EcommerceInfo);
            }
            if (serviceInfo && serviceInfo.length > 0) {
                setServiceData(serviceInfo);
            }
            // if (durationInfo && durationInfo.length > 0) {
            //     setDurationData(durationInfo);
            //     form.setFieldsValue({ 'duration_year': durationInfo[0]?.id })
            //     form.setFieldsValue({ 'duration': durationInfo[0]?.duration })
            // }
        }
    }, [fetchedData])

    useEffect(() => {
        if (durationData) {
            setDurationData(durationData)
            form.setFieldsValue({ 'duration_year': durationData[0]?.id })
        }
    }, [durationData])


    // =============   Billing Checked Start

    useEffect(() => {
        if (billingChecked) {
            setSelectedBilling(billingData)
        }
    }, [billingChecked])

    useEffect(() => {
        if (selectedBilling?.length === billingData?.length) {

            setBillingChecked(true)
        } else {
            setBillingChecked(false)
        }
    }, [selectedBilling, billingData])

    // =============   Billing Checked End
    // =============   ECommerce Checked Start

    useEffect(() => {
        if (eCommerceChecked) {
            setSelectedCommerce(eCommerceData)
        }
    }, [eCommerceChecked])

    useEffect(() => {
        if (selectedCommerce?.length === eCommerceData?.length) {
            setECommerceChecked(true)
        } else {
            setECommerceChecked(false)
        }
    }, [selectedCommerce, eCommerceData])


    // =============   ECommerce Checked End

    // =============   Service Checked Start

    useEffect(() => {
        if (serviceChecked) {
            setSelectedService(serviceData)
        }
    }, [serviceChecked])

    useEffect(() => {
        if (selectedService?.length === serviceData?.length) {
            setServiceChecked(true)
        } else {
            setServiceChecked(false)
        }
    }, [selectedService, serviceData])

    // =============   Service Checked End

    const durationOptiov = durationData?.map((item) => (         // -> Duration Options
        {
            label: item.plans,
            value: item.id
        }
    ))

    const onDurationChange = (value) => {
        const selectedObject = durationData.find((item) => item.id === value);
        setSelectedDuration(selectedObject);
        // form.resetFields()
        // totalAmount

        const Duration = {
            duration_year: selectedObject?.id
        }
        PostDatas(Duration)
    };

    // =============== post ========

    const PostDatas = (value) => {
        request.post(APIURLS.POSTDURATIONDETAILS, value)
            .then(function (response) {
                setFetchedData(response.data)
            })
            .catch(function (error) {
                console.log(error, 'failedddd');
            });
    };


    const onBillingChange = (value) => {
        const selectedObject = billingData.find((item) => item.name === value);


        // Check if the selected object is already in the array
        const isAlreadySelected = selectedBilling?.some((item) => item?.name === value);

        // // If it's not in the array, add it; otherwise, remove it
        if (!isAlreadySelected) {
            setSelectedBilling((prevSelected) => [...prevSelected, selectedObject]);
        } else {
            setSelectedBilling((prevSelected) => prevSelected.filter((item) => item.name !== value));
        }
    };


    const onCommerceChange = (value) => {
        const selectedObject = eCommerceData.find((item) => item.name === value);

        // Check if the selected object is already in the array
        const isAlreadySelected = selectedCommerce.some((item) => item.name === value);

        // If it's not in the array, add it; otherwise, remove it
        if (!isAlreadySelected) {
            setSelectedCommerce((prevSelected) => [...prevSelected, selectedObject]);
        } else {
            setSelectedCommerce((prevSelected) => prevSelected.filter((item) => item.name !== value));
        }
    };

    const onServiceChange = (value) => {
        const selectedObject = serviceData.find((item) => item.name === value);

        // Check if the selected object is already in the array
        const isAlreadySelected = selectedService.some((item) => item.name === value);

        // If it's not in the array, add it; otherwise, remove it
        if (!isAlreadySelected) {
            setSelectedService((prevSelected) => [...prevSelected, selectedObject]);
        } else {
            setSelectedService((prevSelected) => prevSelected.filter((item) => item.name !== value));
        }
    };

    // Calculate total amount based on selected objects

    // const totalAmount =
    // billingData.some((item)=>item.name === selectedBilling.some((item)=>item.name)=>(selectedBilling?.reduce((total, item) => total + item.amount, 0)))
    // selectedBilling?.reduce((total, item) => total + item.amount, 0) +
    // selectedCommerce?.reduce((total, item) => total + item.amount, 0) +
    // selectedService?.reduce((total, item) => total + item.amount, 0);

    // const totalAmount = billingData.reduce((total, billingItem) => {
    //     // Check if the name of the billing item exists in selectedBilling
    //     if (selectedBilling.some(selectedItem => selectedItem.name === billingItem.name)) {
    //         // If it exists, add the amount to the total
    //         return total + billingItem.amount;
    //     }
    //     // If it doesn't exist, return the current total
    //     return total;
    // }, 0);

    const totalAmount = (billingData, selectedBilling, eCommerceData, selectedCommerce, serviceData, selectedService) => {
        const billingTotal = billingData.reduce((total, billingItem) => {
            if (selectedBilling.some(selectedItem => selectedItem.name === billingItem.name)) {
                return total + billingItem.amount;
            }
            return total;
        }, 0);

        const commerceTotal = eCommerceData.reduce((total, commerceItem) => {
            if (selectedCommerce.some(selectedItem => selectedItem.name === commerceItem.name)) {
                return total + commerceItem.amount;
            }
            return total;
        }, 0);

        const serviceTotal = serviceData.reduce((total, serviceItem) => {
            if (selectedService.some(selectedItem => selectedItem.name === serviceItem.name)) {
                return total + serviceItem.amount;
            }
            return total;
        }, 0);

        // You can add similar code for selectedService if needed

        return billingTotal + commerceTotal + serviceTotal;
    };

    const totalAmountResult = totalAmount(billingData, selectedBilling, eCommerceData, selectedCommerce, serviceData, selectedService);

    const BillingAllChecked = (e) => {    // ----> Billing Checked
        setBillingChecked(e.target.checked)
        // if(!e.target.checked){
        //     setSelectedBilling([])
        // }
    }

    const ECommerceAllChecked = (e) => {  // ----> E- Commerce Checked
        setECommerceChecked(e.target.checked)
    }

    const ServiceAllChecked = (e) => {  // ----> Service Checked
        setServiceChecked(e.target.checked)
    }

    useEffect(() => {
        // https://prod.hibiller.com/pricing
        // form.setFieldsValue({ tid: '123' })
        form.setFieldsValue({ merchant_id: '2895899' })
        form.setFieldsValue({ amount: '1.00' })
        form.setFieldsValue({ currency: "INR" })
        form.setFieldsValue({ redirect_url: `${BASE_URL}gate_way/payment_respose/` })
        form.setFieldsValue({ cancel_url: `${BASE_URL}gate_way/payment_respose/` })
        form.setFieldsValue({ language: "EN" })
    }, [])


    const onFinish = (values) => {

        if (loginData != null) {
            if (totalAmountResult != 0) {

                const NewValue = {
                    duration_year: values?.duration_year,
                    billing: selectedBilling,
                    commerce: selectedCommerce,
                    service: selectedService,
                }

                const Payment = {   // CCAvenue
                    amount: values.amount,
                    cancel_url: values.cancel_url,
                    currency: values.currency,
                    language: values.language,
                    merchant_id: values.merchant_id,
                    redirect_url: values.redirect_url,
                    plans: NewValue,
                }
                testCanva(Payment);
                dispatch(setSubScription(NewValue))
            } else {
                toast.info('Please Select any Subscription')
            }
        }
        else {
            handleSignIn();
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const testCanva = async (value) => {
        try {
            const encryptionRes = await request.post(`gate_way/payment_connect/`, value);

            if (encryptionRes.status === 200) {
                const encryptedData = encryptionRes.data;
                setEncRequest(encryptedData?.data);
                setAccessCode(encryptedData?.accesscode);
                setTimeout(async () => {
                    await formRef.current.submit();

                    setSelectedCommerce([]);
                    setSelectedService([]);
                    setSelectedBilling([])
                }, 1000);

            }
        } catch (error) {
            console.log(error);
        }
    }


    // const PostResponse =async (value) => {
    //     // const CCvdatas = ccavenueRes
    //     console.log(value, 'ccv values');
    //    await request.post('gate_way/avanue_datas/', value)
    //         .then(function (response) {
    //             console.log(response.data, 'data params');
    //         }).catch(error => {
    //             console.log(error,'faildddddddd');
    //         })
    // }

    return (
        <Container>
            <Form
                form={form}
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">

                <div style={{ margin: '20px' }}>
                    <Flex center={'true'}>
                        <CustomSelect width={150}
                            name={`duration_year`}
                            onChange={onDurationChange}
                            options={durationOptiov}
                        />
                    </Flex>
                </div>


                <div style={{ marginTop: '20px' }}>
                    <CustomRow space={[24, 24]}>
                        <Col span={24} md={12} lg={14}>
                            <CustomRow space={[12, 12]}>
                                {/* ----------------------- Billing */}

                                {
                                    billingData.length > 0 && (

                                        <Fragment>
                                            <Col span={24}>
                                                <CustomRow>
                                                    <Col span={24} sm={12}>
                                                        <PriceingTitle>Billing</PriceingTitle>
                                                    </Col>
                                                    <Col span={24} sm={12}>
                                                        <Flex end={'true'} >
                                                            <CustomCheckBox onChange={BillingAllChecked} checked={billingChecked} />
                                                            <PriceingSelectTitle>Select All</PriceingSelectTitle>
                                                        </Flex>
                                                    </Col>
                                                </CustomRow>
                                            </Col>
                                            <Col span={24}>
                                                <PricingRow>
                                                    {
                                                        billingData?.map((bill) => (
                                                            <PricingCard key={bill?.key} active={selectedBilling?.some((selectedItem) => selectedItem?.name === bill?.name) ? 'active' : ''} onClick={() => onBillingChange(bill?.name)}>
                                                                {proDomain?.some((domain) => domain?.name === bill?.combination) && (
                                                                    <div style={{ background: proDomain.find((domain) => domain?.name === bill?.combination)?.bgcolor }}>
                                                                        <img src={proDomain.find((domain) => domain?.name === bill?.combination)?.logo} alt="logo" />
                                                                    </div>
                                                                )}
                                                                <h4>{bill?.name}</h4>
                                                                <div className='checked'>
                                                                    <img src={PricingSvgs?.ActiveTick} alt="" />
                                                                </div>
                                                            </PricingCard>
                                                        ))
                                                    }
                                                </PricingRow>
                                            </Col>
                                        </Fragment>
                                    )
                                }

                                {/* ----------------------- E- Commerce */}

                                {
                                    eCommerceData.length > 0 && (
                                        <Fragment>
                                            <Col span={24} sm={12}>
                                                <PriceingTitle>E-Commerce</PriceingTitle>

                                            </Col>
                                            <Col span={24} sm={12}>
                                                <Flex end={'true'}>
                                                    <CustomCheckBox onChange={ECommerceAllChecked} checked={eCommerceChecked} />
                                                    <PriceingSelectTitle>Select All</PriceingSelectTitle>
                                                </Flex>
                                            </Col>
                                            <Col span={24}>
                                                <PricingRow>
                                                    {
                                                        eCommerceData.map((com) => (
                                                            <PricingCard key={com.key} active={selectedCommerce.some((selectedItem) => selectedItem.name === com.name) ? 'active' : ''} onClick={() => onCommerceChange(com.name)}>
                                                                {proDomain?.some((domain) => domain?.name === com?.combination) && (
                                                                    <div style={{ background: proDomain.find((domain) => domain?.name === com?.combination)?.bgcolor }}>
                                                                        <img src={proDomain.find((domain) => domain?.name === com?.combination)?.logo} alt="logo" />
                                                                    </div>
                                                                )}
                                                                <h4>{com.name}</h4>
                                                                <div className='checked'>
                                                                    <img src={PricingSvgs?.ActiveTick} alt="" />
                                                                </div>
                                                            </PricingCard>
                                                        ))
                                                    }
                                                </PricingRow>
                                            </Col>
                                        </Fragment>
                                    )
                                }

                                {/* ------------------------ Services  */}

                                {
                                    serviceData.length > 0 && (
                                        <Fragment>
                                            <Col span={24} sm={12}>
                                                <PriceingTitle>Other</PriceingTitle>

                                            </Col>
                                            <Col span={24} sm={12}>
                                                <Flex end={'true'}>
                                                    <CustomCheckBox onChange={ServiceAllChecked} checked={serviceChecked} />
                                                    <PriceingSelectTitle>Select All</PriceingSelectTitle>
                                                </Flex>
                                            </Col>
                                            <Col span={24}>
                                                <PricingRow>
                                                    {
                                                        serviceData.map((service) => (
                                                            <PricingCard key={service.key} active={selectedService.some((selectedItem) => selectedItem.name === service.name) ? 'active' : ''} onClick={() => onServiceChange(service.name)}>
                                                                {proServices?.some((ser) => ser?.name === service?.combination) && (
                                                                    <div style={{ background: proServices.find((ser) => ser?.name === service?.combination)?.bgcolor }}>
                                                                        <img src={proServices.find((ser) => ser?.name === service?.combination)?.logo} alt="logo" />
                                                                    </div>
                                                                )}
                                                                <h4>{service.name}</h4>
                                                                <div className='checked'>
                                                                    <img src={PricingSvgs?.ActiveTick} alt="" />
                                                                </div>
                                                            </PricingCard>
                                                        ))
                                                    }
                                                </PricingRow>
                                            </Col>
                                        </Fragment>
                                    )
                                }

                            </CustomRow>
                        </Col>

                        <Col span={24} md={12} lg={10}>
                            <CustomRow>
                                <Col span={24}>
                                    <BuyHolderCard>
                                        <h2>HI BILLER</h2>

                                        <Fragment>
                                            {billingData?.length != 0 && <BuyHolderTitle>Billing</BuyHolderTitle>}
                                            <CustomRow space={[12, 12]}>
                                                {
                                                    billingData?.map((bill, index) => (
                                                        <Col span={24} key={index}>
                                                            <Flex spacebetween={'true'} aligncenter={'true'}>
                                                                <BuyHolderSubTitle>
                                                                    <img src={selectedBilling.find((item) => item?.combination === bill?.combination) ? PricingSvgs.Yes : PricingSvgs.Close} alt="logo" />

                                                                    {bill?.name}</BuyHolderSubTitle>
                                                                <BuyHolderSubTitle>₹ {bill?.amount}</BuyHolderSubTitle>
                                                            </Flex>
                                                        </Col>
                                                    ))
                                                }
                                            </CustomRow>


                                            {eCommerceData?.length != 0 &&
                                                <BuyHolderTitle>E - Commerce</BuyHolderTitle>
                                            }
                                            <CustomRow space={[12, 12]}>
                                                {
                                                    eCommerceData?.map((com, index) => (
                                                        <Col span={24} key={index}>
                                                            <Flex spacebetween={'true'} aligncenter={'true'}>
                                                                <BuyHolderSubTitle>
                                                                    <img src={selectedCommerce.find((item) => item?.combination === com?.combination) ? PricingSvgs.Yes : PricingSvgs.Close} alt="logo" />

                                                                    {com?.name}</BuyHolderSubTitle>
                                                                <BuyHolderSubTitle>₹ {com?.amount}</BuyHolderSubTitle>
                                                            </Flex>
                                                        </Col>
                                                    ))
                                                }
                                            </CustomRow>

                                            {
                                                serviceData?.length != 0 &&
                                                <BuyHolderTitle>Services</BuyHolderTitle>
                                            }
                                            <CustomRow space={[12, 12]}>
                                                {
                                                    serviceData?.map((ser, index) => (
                                                        <Col span={24} key={index}>
                                                            <Flex spacebetween={'true'} aligncenter={'true'}>
                                                                <BuyHolderSubTitle>
                                                                    <img src={selectedService.find((item) => item?.combination === ser?.combination) ? PricingSvgs.Yes : PricingSvgs.Close} alt="logo" />

                                                                    {ser?.name}</BuyHolderSubTitle>
                                                                <BuyHolderSubTitle>₹ {ser?.amount}</BuyHolderSubTitle>
                                                            </Flex>
                                                        </Col>
                                                    ))
                                                }
                                            </CustomRow>

                                            {/* <BuyHolderTitle>Duration</BuyHolderTitle>
                                            <CustomRow space={[12, 12]}>

                                                <Col span={24}>
                                                    <Flex spacebetween={'true'} aligncenter={'true'}>
                                                        <BuyHolderSubTitle>{selectedDuration.length !== 0
                                                            ? selectedDuration?.plans
                                                            : durationData[0]?.plans}</BuyHolderSubTitle>
                                                        <BuyHolderSubTitle>{selectedDuration.length !== 0
                                                            ? selectedDuration?.duration
                                                            : durationData[0]?.duration}</BuyHolderSubTitle>
                                                    </Flex>
                                                </Col>
                                            </CustomRow> */}



                                            <BuyHolderTitle>Amount</BuyHolderTitle>
                                            <CustomRow space={[12, 12]}>

                                                <Col span={24}>
                                                    <Flex spacebetween={'true'} aligncenter={'true'}>
                                                        <BuyHolderSubTitle>Duration</BuyHolderSubTitle>
                                                        <BuyHolderSubTitle>{selectedDuration.length !== 0
                                                            ? selectedDuration?.plans
                                                            : durationData[0]?.plans}</BuyHolderSubTitle>
                                                    </Flex>
                                                </Col>

                                                <Col span={24}>
                                                    <Flex spacebetween={'true'} aligncenter={'true'}>
                                                        <BuyHolderSubTitle>Total</BuyHolderSubTitle>
                                                        <BuyHolderSubTitle>{totalAmountResult}</BuyHolderSubTitle>
                                                    </Flex>
                                                </Col>
                                            </CustomRow>

                                            <BtnHolder>
                                                <Button.BuyPrimary text={'Buy'} htmlType={'submit'} />
                                            </BtnHolder>
                                        </Fragment>
                                    </BuyHolderCard>

                                    <Col span={24}>
                                        {/* <CustomInput name={'tid'} label={'tid'} display={'none'} /> */}
                                        <CustomInput name={'merchant_id'} label={'merchant_id'} display={'none'} />
                                        {/* <CustomInput name={'order_id'} label={'order_id'} display={'none'} /> */}
                                        <CustomInput name={'amount'} label={'amount'} display={'none'} />
                                        <CustomInput name={'currency'} label={'currency'} display={'none'} />
                                        <CustomInput name={'redirect_url'} label={'redirect_url'} display={'none'} />
                                        <CustomInput name={'cancel_url'} label={'cancel_url'} display={'none'} />
                                        <CustomInput name={'language'} label={'language'} display={'none'} />
                                    </Col>
                                </Col>
                            </CustomRow>
                        </Col>
                    </CustomRow>
                </div>

                <form
                    ref={formRef}
                    id="nonseamless"
                    method="post"
                    name="redirect"
                    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                >
                    <input
                        type="hidden"
                        id="encRequest"
                        name="encRequest"
                        value={encRequest}
                    ></input>
                    <input
                        type="hidden"
                        name="access_code"
                        id="access_code"
                        value={accessCode}
                    ></input>
                </form>

            </Form>
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={modalwidth}
                modalTitle={modalTitle} modalContent={modalContent} />
        </Container >
    )
}