import { Col, Form, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { CustomInput } from '../../../components/Form/CustomInput'
import ButtonStandard from '../../../components/Form/CustomStandardButton'
import { CustomInputNumber } from '../../../components/Form/CustomInputNumber'
import { CustomSelect } from '../../../components/Form/CustomSelect'
import { useDispatch } from 'react-redux'
import { setBooking } from '../BookingSlice'
import { CustomTextArea } from '../../../components/Form/CustomTextArea'
import { baseRequest } from '../../../utils/request'
import { APIURLS } from '../../../utils/ApiUrls'
import { toast } from 'react-toastify'
import axios from 'axios'

const BookingForm = ({ HandleSignIn, handleOk, isloading, trigger }) => {

    const [loading, setLoading] = useState(false)
    useEffect(() => {
        form.resetFields()
    }, [trigger])

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        const data = {
            ...values,
            projectName: 'Hi Biller'
        }
        console.log(data, 'lll');
        postDemo(data);
    };

    const postDemo = async (data) => {
        setLoading(true);
        axios.get(`https://dev.api.ideauxbill.in/${APIURLS.POSTDEMOREQ}`, { params: data })
            .then(function (response) {
                setLoading(false);
                dispatch(setBooking(data));
                handleOk();
                toast.success('Demo Request Send Successfully')

            }).catch(function (error) {
                setLoading(false);
                toast.error("Something went wrong")
                console.log(error, 'faild');
            })
    }

    const onFinishFailed = (error) => {
        console.log(error);
    }

    const heardData = [
        {
            label: 'Google Search',
            value: 'Google Search'
        },
        {
            label: 'Social Media',
            value: 'Social Media'
        },
        {
            label: 'Facebook',
            value: 'Facebook'
        },
        {
            label: 'Email',
            value: 'Email'
        },
        {
            label: 'Ads',
            value: 'Ads'
        },
        {
            label: 'Other...',
            value: 'Other...'
        },
    ]

    return (
        <Form
            form={form}
            labelCol={{
                span: 24,
            }}
            wrapperCol={{
                span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <CustomRow space={[12, 12]}>
                <Col span={24}>
                    <CustomInput placeholder={'Name'} name={'name'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter your name !',
                            }
                        ]}
                    />
                </Col>

                <Col span={24}>
                    <CustomInput placeholder={'Email Address'} type={'email'} name={'email'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Email Address!',
                            }
                        ]}
                    />
                </Col>

                <Col span={24}>
                    <CustomInputNumber minLength={10} maxLength={10} placeholder={'Contact Number'} name={'phoneNumber'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Contact Number !',
                            },
                        ]}
                        precision={0}
                    />
                </Col>
                <Col span={24}>
                    <CustomInput placeholder={'Role'} name={'role'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Role !',
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <CustomSelect options={heardData} placeholder={'How did you hear about us'} name={'heardAboutUs'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter Role !',
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <CustomInput placeholder={'Location'} name={'location'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter your location !',
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <CustomTextArea placeholder={'Message'} name={'message'}
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter your message !',
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <CustomInput name={'projectName'} display={'none'} />
                </Col>
            </CustomRow>
            <div style={{ marginTop: '15px',display:'flex', }}>
                <ButtonStandard.PrimarySeconty loading={loading} style={{ width: '100%', padding: '20px 0', border: '0', }} text={'Submit'} className='GGG' htmlType={'submit'} />
                <ButtonStandard.Danger loading={loading} style={{ width: '100%', padding: '20px 0', border: '0', }} text={'Cancel'} className='GGG' htmlType={'reset'} />
            </div>
        </Form>
    )
}

export default BookingForm
