import React from "react";
import { FifMainSec, Maindiv } from "../style";
import { Col, Row } from "antd";
import img1 from "../../../Images/home/p_01.png";
import img2 from "../../../Images/home/p_2.png";
import img3 from "../../../Images/home/3d_dashboard_analyst-1.png";
import { CustomRow } from "../../../components/CustomRow";
import { infoData } from "../../../assets/data";

export const FifthSection = () => {
  return (
    <FifMainSec>
     <div className="container">
        <h2>One less thing to worry about</h2>      

        <h3>your free Wave account gets you access to all this and more:</h3>

       <div style={{marginTop:'30px'}}>
       <CustomRow space={[24,24]}>
          {
            infoData?.map((item)=>(
              <Col key={item.key} span={24} md={12}>
                <div className="wrapper">
                  <img src={item.logo} alt={item?.altternative} title={` ${item?.altternative}`} />

                  <p>{item.content}</p>
                </div>
              </Col>

            ))
          }
        </CustomRow>
       </div>
     </div>
    </FifMainSec>
  );
};
