import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import { CustomCardView } from '../../../components/CustomCardView';
import styled from 'styled-components';
import Profile from './Profile';
import MyOrder from './MyOrder';
import ChangePass from './ChangePass';
import ViewOderTable from './ViewOderTable';

const { Content } = Layout;
const { TabPane } = Tabs;

const ProfileTab = () => {
  const [tabPosition, setTabPosition] = useState('top');
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    // Load the active tab from local storage if available
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  // Update the local storage when the active tab changes
  useEffect(() => {
    if (activeTab) {
      localStorage.setItem('activeTab', activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setTabPosition('top');
      } else {
        setTabPosition('left');
      }
    };

    // Initial check when the component mounts
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const TabHeader = styled(Tabs)`
    :where(.css-dev-only-do-not-override-190m0jy).ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    :where(.css-dev-only-do-not-override-190m0jy).ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      position: relative;
      display: flex;
      transition: opacity 0.3s;
      margin-top: 66px;
    }
    .ant-tabs , .ant-tabs-nav {
        background-color: #fff !important;
        padding: 20px;
        width: 20%;
    }
    @media screen and (min-width:768px) and (max-width:1024px) {
      .ant-tabs , .ant-tabs-nav {
        width: 30%;
    }
    }
    @media screen and (max-width:767px) {
      .ant-tabs , .ant-tabs-nav {
        width: 100%;
    }
    }
  `;

  return (
    <Layout>
      <Content style={{ margin: '24px 16px', padding: 0 }}>
        {/* <CustomCardView> */}
        <TabHeader tabPosition={tabPosition} activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Profile" key="1">
            <Profile />
          </TabPane>
          <TabPane tab="My Order" key="2">
            <ViewOderTable />
          </TabPane>
        </TabHeader>
        {/* </CustomCardView> */}
      </Content>
    </Layout>
  );
};

export default ProfileTab;
