import React from 'react'
import { FirstSection } from './Partials/FirstSection'
import { SecondSection } from './Partials/SecondSection'
import { ThirdSection } from './Partials/ThirdSection'
import { FourthSection } from './Partials/FourthSection'
import { FifthSection } from './Partials/FifthSection'
import { SixthSection } from './Partials/SixthSection'
import Footer from '../Footer/Partials/Footer'
import { TestimonialSection } from './Partials/TestimonialSection'
import VideoSection from './Partials/VideoSection'
import { SwiperHolder } from './Partials/Swiper'

export const HomePage = ({handleSignIn}) => {
  return (
    <div>
      <FirstSection/>
      {/* <SecondSection/> */}
      <ThirdSection/>
      <FourthSection/> 
      <FifthSection/>
      <VideoSection handleSignIn={handleSignIn}/>
      {/* <SwiperHolder/> */}
      <SixthSection/>
      {/* <TestimonialSection/> */}
      <Footer />
    </div>
  )
}
