import React from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { Card, Col } from 'antd'
import Flex from '../../../components/Flex'
import { CardSection, PriceTextSecnt } from '../Pricingstyle'
import PiCurrencyInrBold from 'react-icons/pi'
import { pricingList } from '../../../assets/data'
import { Holder } from './Style'


const PricingSection = () => {

    return (
        <PriceTextSecnt>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={24}>
                    <Flex center={'true'}>
                        <h1>Plans to suit your business,
                            <br/>That Helps You Scale Seamlessly</h1>
                    </Flex>
                </Col>
                <Col span={24} md={24}>
                    <Flex center={'true'}>
                        <h3>Pricing Details</h3>
                    </Flex>
                </Col>
                <Col span={24} md={24}>
                    <Flex center={'true'}>
                        <h2>Get the flexibility to manage
                            subscription problems you have
                            today. And the ones that will creep
                            up a year from now.</h2>
                    </Flex>
                </Col>
            </CustomRow>
            <div className='priceBoxstyle'>
                <CardSection>
                    <CustomRow space={[24, 24]}>
                        <Col span={24}>
                            <div>
                                <h5>Price the Way That Makes Sense for Your Business</h5>
                                <p>New revenue opportunities could be hiding behind
                                    untested pricing. Hi Biller lets you roll out
                                    new pricing plans rapidly with flexible billing
                                    frequencies. Whether you follow a flat-fee,
                                    pay-as-you-go, or a completely customized
                                    subscription pricing model, Hi Biller does it all.</p>
                            </div>
                        </Col>
                    </CustomRow>
                </CardSection>
            </div>

            <CustomRow space={[24, 24]}>
                {
                    pricingList?.map((item) => (
                        <Col span={24} sm={12} md={8} lg={6}>
                            <Holder>
                                <img src={item?.svg} alt={item?.title} title={`Hi Biller ${item?.title}`}/>
                                <span>{item?.title}</span>
                            </Holder>
                        </Col>
                    ))
                }
            </CustomRow>
        </PriceTextSecnt>
    )
}

export default PricingSection