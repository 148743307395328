import React, { useState, useEffect } from 'react';
import { Iconplace, LogoPlace, NavCollapse, NavProfile, TopNavBar } from "./stylenav";
import { MdOutlineMenu } from 'react-icons/md'
import { AiOutlineClose, AiOutlineUser } from 'react-icons/ai'
import { Form } from 'antd';
import { NavData } from '../data';
import ButtonStandard from '../../../components/Form/CustomStandardButton';
import { CustomModal } from '../../../components/CustomModal';
import Flex from '../../../components/Flex';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/Logo.png'
import UserSignin from '../../Auth/Components/UserSignin';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, selectCurrentUser, setCredentials } from '../../Auth/authSlice';
import Button from '../../../components/Form/CustomButton';
import { IoMdArrowDropdown } from 'react-icons/io';
import { removeSubScription } from '../../Mainpages/subScriptionSlice';



const Navmenus = () => {

  const [isOpen, setIsOpen] = useState(true);
  const [date, setDate] = useState('')
  const [form] = Form.useForm();

  const [modalwidth, setModalWidth] = useState(0)

  const [trigger, setTrigger] = useState(0)

  const [reset,setReset] = useState(0)




  // ======  Modal Open ========
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ======  Modal Title and Content ========
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  // const dispatch = useDispatch()

  const [isSignedIn, setIsSignedIn] = useState(false);

  const dispatch = useDispatch()

  const FormReset = () => {
    setReset(reset + 1)
  }


  const FormExternalClose = () => {
    handleOk();
  };


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    FormReset()
  };

  const nevigate = useNavigate()

  const [isSticky, setSticky] = useState(true);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 500);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const Signout = () => {
    dispatch(logOut());
    dispatch(removeSubScription());
    handleOk()
    localStorage.removeItem('persist')

  }


  const AdminLogOut = () => {
    setModalContent(<LogOutModal />);
    setModalTitle("Log Out");
    showModal();
  }

  const SigninPage = () => {
    setModalContent(<UserSignin handleOk={handleOk} />);
    setModalTitle("");
    showModal();
  }

  const handleClickScroll = ({ path }) => {
    const id = path.slice(1)

    const element = document.getElementById(id);
    if (id === 'Home') {
      window.scrollTo(0, 0);
      window.location.hash = ''
    }
    else if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
      window.location.hash = path
    }

  }

  const dateFormat = 'YYYY-MM-DD'
  const DateChange = (e) => {

    const selectedDate = e
    // const formattedDate = moment(selectedDate).format(dateFormat);
    // setDate(formattedDate)
  }

  const handleLogout = () => {
    setIsSignedIn(false);
    setModalContent(<LogOutModal />);
    setModalTitle("Log Out");
    setModalWidth(400)
    showModal();

  }

  const LogOutModal = () => (
    <div>
      <h1 style={{ fontSize: '1.2rem' }}>Are you Sure You Want to Logout ?</h1>
      <br />
      <Flex gap={'20px'} W_100 center verticallyCenter>
        <Button.Success text={'Logout'} onClick={Signout} />
        <Button.Danger text={'Cancel'} onClick={handleOk} />
      </Flex>
    </div>
  )

  const handleSignIn = () => {
    setTrigger(trigger + 1)
    setIsSignedIn(true);
    setModalContent(<UserSignin handleOk={handleOk} trigger = {trigger} />);
    setModalTitle("");
    setModalWidth(900)
    showModal();
  };

  const loginData = useSelector(selectCurrentUser)

  return (
    <>
      <TopNavBar >
        <LogoPlace to={'/hibiller'}>
          <img src={Logo} alt="Hi Biller logo" title="Hi Biller logo" />
        </LogoPlace>

        <NavCollapse State={`${isOpen ? "none" : "flex"}`}>
          {NavData.map(({ name, path }, i) => {
            return (
              <>
                <NavLink key={i} to={path}
                  onClick={() => {
                    setIsOpen((prev) => !prev);
                  }}>
                    <a href={path}>{name}</a>
                  
                </NavLink>
              </>
            );
          })}

          {loginData ? (
            <ButtonStandard.Primary
              text={'Logout'}
              className='MediaScreenshow'
              style={{width:'20%'}}
              icon={<AiOutlineUser style={{ marginRight: '10px' }} />}
              onClick={() => handleLogout()} 
            />
          ) : (
            <ButtonStandard.Primary
              text={'Sign In'}
              style={{width:'20%'}}
              className='MediaScreenshow'
              icon={<AiOutlineUser style={{ marginRight: '10px' }} />}
              onClick={() => handleSignIn()}
            />
          )}
        </NavCollapse>

        <Flex>
          {/* <NavProfile>
            <div onClick={() => nevigate(`/navprofile`)}>+ {loginData}</div>
          </NavProfile> */}

          {loginData ? (

            <NavProfile>
              <div style={{fontWeight:'bold',display:'flex',color:'#5F2DED'}} onClick={() => nevigate(`/navprofile`)}> <IoMdArrowDropdown  />{loginData}</div>
            </NavProfile>
          ) : (
            <NavProfile>
              <div style={{color:'#5F2DED'}} onClick={() => nevigate(`/navprofile`)}></div>
            </NavProfile>

          )}

          {loginData ? (

            <ButtonStandard.SignInBtn
              text={'Logout'}
              className='btnstylesmoble'
              icon={<AiOutlineUser style={{ marginRight: '10px' }} />}
              onClick={() => handleLogout()} 
            />
          ) : (
            <ButtonStandard.SignInBtn
              text={'Sign In'}
              className='btnstylesmoble'
              icon={<AiOutlineUser style={{ marginRight: '10px' }} />}
              onClick={() => handleSignIn()}
            />

          )}
        </Flex>

        <Iconplace
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          {isOpen ? <MdOutlineMenu /> : <AiOutlineClose />}
        </Iconplace>
      </TopNavBar>
      <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
        width={modalwidth}
        modalTitle={modalTitle} modalContent={modalContent} />
      <Outlet />

    </>
  )
}

export default Navmenus;