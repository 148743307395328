import styled from "styled-components";
import { THEME } from "../../../theme";

export const PrivacyWrapper = styled.div`

margin: 30px;
    & h1{
        color:${THEME.primary};
        text-align: center;
        padding:20px 0px
    }

    & h2{
        color:${THEME.primary};
        padding-bottom: 15px;
    }

    & ul{
        padding:0px 40px;
        padding-bottom: 10px;
    }

    & span{
        font-weight: 900;
    }

    .mainText{
        font-size: 16px;
        text-align: center;
        padding-bottom: 30px;
    }
    .subText {
        padding-top: 20px;
        text-decoration:underline;
        text-align: center;
    }
    & p{
        font-size: 16px;
        padding-bottom: 10px;
    }

`