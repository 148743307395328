
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    subScription:{}
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSubScription: (state, action) => {
            state.subScription= action.payload
        },
        removeSubScription: (state, action) => {
            state.subScription = {}
        }
    }
})

export const { setSubScription, removeSubScription } = authSlice.actions
export const selectCurrentSubScription = (state) => state.sub.subScription
export default authSlice.reducer