import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.css';
import user from '../../../assets/user.webp' 

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { testimonialDAta } from '../../../assets/data';
import styled from 'styled-components';
import { FaQuoteLeft } from 'react-icons/fa';

export const SwiperHolder = () => {
    return (
        <Container>
            <h2 className="title">Testimonial</h2>
        <div style={{ padding:'2rem 0',maxWidth:'900px',margin:'auto' }}>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {
                    testimonialDAta?.map((item) => (
                        <SwiperSlide>
                            <Wrapper>
                            <div className="slide">
                                <img src={user} title={`Hi Biller user Image ${item?.key}`} alt={`user Image ${item?.key}`} />
                                <p>{item?.content}</p>
                                <span className='quote-icon'> <FaQuoteLeft /></span>

                                {/* <div className='details'>
                                <span className="name">VIJAY</span>
                            </div> */}
                            </div>
                            </Wrapper>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
        </Container>
    );
}
const Container = styled.section`
    /* height: 100%;
    width: 100%; */
    /* gap:30px; */
    background:#e3f2fd;
    padding:3rem 0;


  .title {
    font-size: 42px;
    font-weight: 600;
    color: #020202;
    text-align:center;
  }
`

const Wrapper = styled.div`
       display:flex;
    .slide{
        display: flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        row-gap:30px;
        padding:50px 0;

        img{
            width: 170px;
            height: 170px;
            object-fit:cover;
            border-radius:50%;
        }

        p{
            padding: 0 100px;
            text-align:center;
            font-size:18px;
            font-weight:400;
            color:#333;
            @media screen and (max-width:768px) {
                /* font-size:14px; */
                padding:0 20px;
            }
        }
        span.quote-icon {
            font-size:30px;
            color:#4070f4;
        }
        .details{
            .name{
                font-size:14px;
                font-weight:600;
                color:#333;
            }
        }
    }
`
