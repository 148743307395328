import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { Col, Divider } from 'antd'
import styled from 'styled-components'
import { CustomPageFormSubTitle } from '../../../components/CustomPageTitle'
import Flex from '../../../components/Flex'
import { Step, Stepper } from 'react-form-stepper'
import { useParams } from 'react-router-dom'
import request from '../../../utils/request'

const CardOverview = styled.div`
background: #fff;
padding: 20px;
& h5 {
    font-size: 20px;
    font-weight: 900;
    margin: 20px 10px;
    color: #000;
  }
  & h2 {
     margin: 8px 0;
  }
  & h2 span{
     font-size: 14px;
     margin-left: 8px;
  }
.CardBox {
  border-radius: 5px;
  background-color: #f6f1f1ad;
  border: 2px solid;
  padding: 15px;
  color: #000;
  margin-bottom: 20px;
  & h1 span{
     font-weight: 300;
  }
  & p {
    font-size: 14px;
  }
  & h6 {
    font-size: 14px;
    font-weight: 800;
  }
  
}
.Onprocessing {
  background-color: #eae4d5;
  font-weight: 500;
  border-radius: 7px;
  color: #9e7544;
  padding: 6px 10px;
  margin: 0 5px;
}
.md_space_2 {
  margin: 15px 0;
}
.OnDelivery {
  background-color: #009f7f29;
  font-weight: 500;
  border-radius: 7px;
  color: #009f7f;
  margin: 0 5px;
  padding: 6px 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.md_FlowRoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 767px) and (max-width:1200px) {
    display: flow-root !important;
  }
}
.ant-divider-horizontal {
    min-width:50% !important;
    width: 50% !important;
  }
`

const MyOrder = () => {

  // <Stepper
  //   steps={[{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }]}
  //   activeStep={2}
  // />
  const { id } = useParams();

  const URLS = 'gate_way/pick_pay_histry'

  const [detailsGet, setDetailsGet] = useState({})

  const OrderData = [
    {
      OrderDate: detailsGet?.trans_date,
      card_name: detailsGet?.card_name,
      price: detailsGet.amount,
      totalprice: detailsGet.amount,
      orderID: detailsGet?.order_id,
      orderstatus: detailsGet?.order_status,
      trackingid: detailsGet?.tracking_id,
      
    }
  ]

  const tableData = [
    {
      item: 'Alfreds Futterkiste',
      qty: '3',
      price: '345',
    },
    {
      item: 'Centro comercial Moctezuma',
      qty: '1',
      price: '435',
    },
  ];


  const GetSupplier = () => {
    request.get(`${URLS}/${id}/`)
      .then(function (response) {
        setDetailsGet(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetSupplier()
  }, [])

  return (
    <div style={{marginTop:'30px'}}>
      <CustomRow space={[24, 24]}>
        <Col span={24} md={24} lg={8}>                        {/*   // -------- main section  */}
          <CardOverview>
            <CustomPageFormSubTitle Heading={'My Orders'} />
            {OrderData.map((data, index) => (
              <div key={index} className='CardBox'>
                <div className='md_FlowRoot'>
                  <h1>Order ID&nbsp;<span>{data.orderID}</span></h1>
                  <div className='Onprocessing'>{data.orderstatus}</div>
                </div>
                <Divider />
                <Flex spacebetween={true} aligncenter={true} className='md_space_2'>
                  <p>Payment Date</p>
                  <p>{data.OrderDate}</p>
                </Flex>
                <Flex spacebetween={true} aligncenter={true} className='md_space_2'>
                  <p>Card Name</p>
                  <p>{data.card_name}</p>
                </Flex>
                <Flex spacebetween={true} aligncenter={true} className='md_space_2'>
                  <p>Tracking Id</p>
                  <p>{data.trackingid}</p>
                </Flex>
                
                <Flex spacebetween={true} aligncenter={true} className='md_space_2'>
                  <h6>Amount</h6>
                  <h6>{data.price}</h6>
                </Flex>
                <Flex spacebetween={true} aligncenter={true} className='md_space_2'>
                  <h6>Total Price</h6>
                  <h6>{data.totalprice}</h6>
                </Flex>
              </div>
            ))}
          </CardOverview>
        </Col>
        <Col span={24} md={24} lg={16}>                       {/*   // -------- main section  */}
          <CardOverview>
            <h5>Order Details - {detailsGet?.card_name}</h5>
            <div className='CardBox' style={{ border: '0px' }}>
              <CustomRow space={[24, 24]}>
                <Col span={24} md={12}>
                  <Flex aligncenter={true}>
                    <h1>Order Status:</h1>
                    <div className='Onprocessing'>
                      {detailsGet?.order_status}
                    </div>
                  </Flex>
                </Col>
                <Col span={24} md={12}>
                  <Flex aligncenter={true}>
                    <h1>Payment Status:</h1>
                    <div className='OnDelivery'>
                      {detailsGet?.payment_mode}
                    </div>
                  </Flex>
                </Col>
              </CustomRow>
            </div>


            <CustomRow space={[24, 24]}>              {/*   // -------- Shipping Address  */}
              <Col span={24} md={12}><br />

                <h2>Billing City :<span>{detailsGet?.billing_city}</span></h2>
                <h2>Billing Email :<span>{detailsGet?.billing_email}</span></h2>
                <h2>Billing Name :<span>{detailsGet?.billing_name}</span></h2>
                <h2>Billing State :<span>{detailsGet?.billing_state}</span></h2>
                <h2>Billing country :<span>{detailsGet?.billing_country}</span></h2>
                <h2>Billing notes :<span>{detailsGet?.billing_notes}</span></h2>
                <h2>Billing zip :<span>{detailsGet?.billing_zip}</span></h2>
                <Divider />
                <h2>Currency :<span>{detailsGet?.currency}</span></h2>
                <h2>Discount Value: <span>{detailsGet?.discount_value}</span></h2>
                <h2>ECI Value: <span>{detailsGet?.eci_value}</span></h2>
                <h2>Bank Reff.No: <span>{detailsGet?.bank_ref_no}</span></h2>
                <h2>Bin country: <span>{detailsGet?.bin_country}</span></h2>
                <h2>Vault: <span>{detailsGet?.vault}</span></h2>
              </Col>
              <Col span={24} md={12}>
                <h2>Status Code :<span>{detailsGet?.status_code}</span></h2>
                <h2>Status Message :<span>{detailsGet?.status_message}</span></h2><br />
                <h2>Delivery Name :<span>{detailsGet?.delivery_name}</span></h2>
                <h2>Delivery Address :<span>{detailsGet?.delivery_address}</span></h2>
                <h2>Delivery City :<span>{detailsGet?.delivery_city}</span></h2>
                <h2>Delivery country :<span>{detailsGet?.delivery_country}</span></h2>
                <h2>Delivery State :<span>{detailsGet?.delivery_state}</span></h2>
                <h2>Delivery Tel :<span>{detailsGet?.delivery_tel}</span></h2>
                <h2>Delivery zip :<span>{detailsGet?.delivery_zip}</span></h2>
                <Divider />
                <h2>Offer Code :<span>{detailsGet?.offer_code}</span></h2>
                <h2>Offer type :<span>{detailsGet?.offer_type}</span></h2>
                <h2>Response Code :<span>{detailsGet?.response_code}</span></h2>
              </Col>
            </CustomRow>
            <Divider />

            {/* ------- Stepper start------------- */}
            {/* <Stepper activeStep={1}>
              <Step label="Order Pending" />
              <Step label="Order Processing" />
              <Step label="Order At Local Facility" />
              <Step label="Order Out For Delivery" />
              <Step label="Order Completed" />
            </Stepper> */}

            {/* ---------- Table Datas ---------- */}

            {/* <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.item}</td>
                    <td>{row.qty}</td>
                    <td>{row.price}</td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </CardOverview>
        </Col>
      </CustomRow>
    </div>
  )
}

export default MyOrder