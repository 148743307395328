import React, { useEffect, useState } from 'react'
import { CustomRow } from '../../../components/CustomRow'
import { Col } from 'antd'
import { CustomPageTitle } from '../../../components/CustomPageTitle'
import { TableIconHolder } from '../../../components/CommonStyled'
import { useNavigate } from 'react-router-dom'
import { CustomModal } from '../../../components/CustomModal'
import Flex from '../../../components/Flex'
import { THEME } from '../../../theme'
import { CustomTable } from '../../../components/Form/CustomTable'
import { AiOutlineFundView } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { getOrder, getOrderError, getOrderStatus, selectAllOrder } from '../OrderSlice'
import { CommonLoading } from '../../../components/CommonLoading'

const ViewOderTable = () => {

    const navigate = useNavigate();

    const [dataSource, setDataSource] = useState([])

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    // ----------  Form Reset UseState ---------
    const [formReset, setFormReset] = useState(0);
    const [modelwith, setModelwith] = useState(0);
    const [trigger, setTrigger] = useState(0)

    // ===== Modal Functions Start =====

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const dispatch = useDispatch()

    const AllOrder = useSelector(selectAllOrder)
    const OrderStatus = useSelector(getOrderStatus)

    useEffect(() => {
        setDataSource(AllOrder)
    }, [AllOrder])

    useEffect(() => {
        dispatch(getOrder())
    }, [])


    const columns = [
        {
            title: 'Sl No',
            render: (item, value, index) => index + 1,
        },
        {
            title: 'Order ID',
            dataIndex: 'order_id'
        },
        {
            title: 'Amount',
            dataIndex: 'amount'
        },
        {
            title: 'Date',
            dataIndex: 'trans_date'
        },
        {
            title: 'Tracking ID',
            dataIndex: 'tracking_id'
        },
        {
            title: 'Action',
            render: (record) => {
                return (
                    <Flex center={"true"} gap={'10px'}>
                        <TableIconHolder color={THEME.blue} size={'22px'} onClick={() => navigate(`/myorder/${record.id}`)}>
                            <AiOutlineFundView />
                        </TableIconHolder>
                    </Flex>
                )
            }
        },
    ]

    const Order = [
        { id: '2213', amount: '5345', date: '20-8-2023', Tracking: '#678' }
    ]
    let content;

    if (OrderStatus === 'loading') {
        content = <CommonLoading />
    } else if (OrderStatus === 'succeeded') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    } else if (OrderStatus === 'failed') {
        const rowKey = (dataSource) => dataSource.id;
        content = <CustomTable columns={columns} data={dataSource} rowKey={rowKey} />
    }

    return (
        <div>
            <CustomRow space={[24, 24]}>
                <Col span={24} md={15}>
                    <CustomPageTitle Heading={'View Order Details'} />
                </Col>
                <Col span={24} md={9}>
                </Col>
            </CustomRow>
            {/* <CustomTable columns={columns} data={Order} /> */}
            {content}
            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} width={modelwith} modalTitle={modalTitle} modalContent={modalContent} />
        </div>
    )
}

export default ViewOderTable