export const faqsData = [
    {
      question: "What is a GST bill ?",
      answer:"An invoice or a GST bill is a list of goods sent or services provided, along with the amount due for payment. You can create GST compliant invoices using HI Biller software.",
      open: false
    },
    {
      question: "Who should issue GST bills ?",
      answer: " If you are a GST registered business, you need to provide GST-compliant invoices to your clients for sale of goods and/or services. Your GST registered vendors will provide GST-compliant purchase invoices to you.",
      open: false
    },
    {
      question: "What can I do with Hi Biller ?",
      answer: "With Hi Biller you can create all categories of Billing documents like Sales Invoices, Sales Returns, Purchase Invoices, Purchase Orders, Estimates, Manage Inventory in real time with Stock In & Stock Out options, and generate Delivery Challans for Invoices directly. Barcode Scanner and Thermal Print options are also available.",
      open: false
    },
    {
      question: "Which types of business use Hi biller ?",
      answer: "Hi biller is designed for manufacturers, wholesalers, traders, retailers, distributes, and service-based businesses. It is a GST Invoice Software to help businesses with quick invoicing, track inventory management, payment tracking, monitoring of cash & bank transactions, exporting reports that are compatible with Tally and generating ready-for-filing GST reports.",
      open: false
    },
    {
      question: "Is this subscription for all my business ?",
      answer: "No. You are upgrading only 1 business. You! The viewer!",
      open: false
    },
    {
      question: "Is my business data safe on Go GST Bill ?",
      answer: "Yes, your data are totally safe and secure with us. We use the most secured cloud server, SSL, Encryption, firewall, and many other techniques to keep your valuable data fully secure.",
      open: false
    },
    {
      question: "How do I use Hi Biller ?",
      answer: "Register your company for Free. On registration, you will need to activate your account. Once activated, your company will be signed up and you can start to use Hi Biller. You can use Hi Biller on desktop.",
      open: false
    },
  ];
  