import React, { useState } from "react";
import { CustomRow } from '../../../components/CustomRow';
import { Col } from 'antd';
import Flex from '../../../components/Flex';
import styled from 'styled-components';
import Faq from 'react-faq-component';
import { data, faqsData } from './data';
import FAQ from "./Faq";
import { FaqAnswer, FaqQuestion } from "./FaqStyle";

const FaqContainer = styled.div`
  margin: 15px;
  padding: 28px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.1s ease;
  

  &.open {
    margin-bottom: 15px;
  }

  &:hover {
    background: #f5f5f5;
  }


  .elqAFO::after {
    content: "${props => (props.open ? '-' : '+')}"; 
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 11px;
    height: 28px;
    transition: all 0.2s ease;
    background-color: #2b62c9;
    padding: 3px 14px;
    border-radius: 50%;
    color: white;
}


/* .elqAFO:hover::after {
    background-color: #2b62c9;
} */

`;


// Define the FaqPage styled component
const FaqPage = styled.div`
  margin-top: 20px;
  max-width:1150px;
  margin:auto;
  & h4 {
    font-size: 36px;
    font-family: 'Gabarito', cursive;
    font-weight: 700 !important;
    color: #000;
    & span {
      color: #a1a1a2;
      font-size: 36px;
      font-family: 'Gabarito', cursive;
      font-weight: 700 !important;
    }
  }
  & h3 {
    font-weight: 700 !important;
    font-size: 16px;
  }
  & h2 {
    font-weight: 500 !important;
    font-size: 18px;
    color: #a1a1a2;
  }
  & a {
    font-weight: 500 !important;
    font-size: 18px;
    color: #000;
  }
  & .ColumSections {
    margin-left: -20px !important;
    margin-right: -20px !important;
    background: purple;
  }
`;


const FAQContainer = styled.div`
width:100%;
`;

export default function PricingSection() {
    
    const [faqs, setFaqs] = useState(faqsData);

    const FaqAnswer = styled.div`
    margin-top: 18px;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 8px;
`;

    const toggleFAQ = (index) => {
        setFaqs((prevFaqs) =>
            prevFaqs.map((faq, i) => {
                if (i === index) {
                    return { ...faq, open: !faq.open };
                } else {
                    return { ...faq, open: false };
                }
            })
        );
    };

    const FAQ = ({ faq, index, toggleFAQ }) => {
        return (
            <FaqContainer className={faq.open ? "open" : ""} onClick={() => toggleFAQ(index)}>
                <FaqQuestion>{faq.question}</FaqQuestion>
                {faq.open && <FaqAnswer>{faq.answer}</FaqAnswer>}
                
            </FaqContainer>
            
        );
       
    };


    return (
        <>
            <FaqPage>
                <CustomRow space={[24, 24]} style={{ marginTop: '20px' }}>
                    <Col span={24} md={24}>
                        <Flex center={'true'}>
                            <h4>Frequently Asked <span>Questions</span></h4>
                        </Flex>
                    </Col>
                    <Col span={24} md={24}>
                        <Flex center={'true'}>
                            <h2>Need more help? Check out our<a>  Help Center </a> or  <a>get in touch</a> </h2>
                        </Flex>
                    </Col>
                    <Col span={24} md={24}>
                        <Flex center={'true'}>
                            <FAQContainer>
                                {faqs.map((faq, i) => (
                                    <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} key={i} />
                                ))}
                            </FAQContainer>
                        </Flex>
                    </Col>
                </CustomRow>
            </FaqPage>
        </>
    );
}
