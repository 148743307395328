import styled from "styled-components";

export const FooterSectionStyle = styled.div`
 /* background: linear-gradient(0deg, #513D82 -26.26%, #299A95 124.33%); */
 background: #FFF;
 color: #000;
  /* color: #fff; */
  padding: 60px 50px;

  & h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  & h3 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  & p {
    margin-bottom: 16px;
  }
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
`;

export const CopyRightFooter = styled.div`
  /* background: linear-gradient(0deg, rgba(41, 154, 149, 0.70) 0%, rgba(82, 59, 130, 0.70) 164.41%); */
  background: #000;
  color: #FFF;
  /* color: #fff; */
  padding: 40px 50px;
  .footermedia{
   display: flex;
   gap: 10px;

   a{
    transition:0.5s ease-out;
    &:hover{
      transform:translateY(-8px);
    }
   }
  }
  /* @media screen and (min-width: 601px) and (max-width: 992px) {
   padding: 40px 50px;
  }
  @media screen and (max-width: 600px) {
   padding: 0px 5px;
  } */
`;

export const CopyWrightHolder = styled.div`
font-size:18px;
  & a{
    font-size:20px;
    font-weight:800;
    color:#fff;
    transition:0.5s ease-out;
    &:hover{
      text-decoration:underline;
      letter-spacing:.1rem;
      font-weight:600;
    }
  }
`
export const NavFooterLink = styled.div`
  display:flex;
  flex-direction:column;
  gap:10px;

  & a{
    text-decoration:none;
    cursor: pointer;
    color:#000;
    font-size:.95rem;
    transition:0.5s ease-out;

    &:hover{
      transform:translateY(-5px);
    }
  }
`