import styled from "styled-components"
import { THEME } from "../../../theme"

export const PricingCard = styled.div`
    height:100px;
    width:255px;
    background:#fff;
    border:2px solid ${props => props.active ? '#007FFE' : '#D7D7D7'};
    display:flex;
    align-items:center;
    column-gap:20px;
    cursor: pointer;
    position:relative;
    border-radius:10px;
    padding-left:35px;

    & h4{
        font-size:20px;
        font-weight:700;
        color:#000;
    }

    & div{
        width:38px;
        height:38px;
        border-radius:10px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    & div.checked{
        position:absolute;
        height:30px;
        width:34px;
        align-items:center;
        justify-content:center;
        display: flex;
        padding: 6px;
        z-index:1;
        box-shadow: 0px 4px 4px 0px rgba(0, 127, 255, 0.17);
        border-radius:50%;
        top:-15px;
        border:2px solid ${props => props.active ? '#007FFE' : '#D7D7D7'};
        background:${props => props.active ? '#007FFE' : '#fff'};
        right:-16px;
    }
`
export const PricingRow = styled.div`
    display:flex;
    align-items:center;
    gap:42px;
    justify-content:center;
    margin: 75px 10px;
    flex-wrap:wrap;

    @media ${THEME.DESKTOP} {
        justify-content:flex-start;

  }
`

export const PriceingTitle = styled.h1`
font-weight:700;
font-size:32px;
/* margin-top:17px; */
`

export const PriceingSelectTitle = styled.h3`
font-weight:600;
font-size:24px;
`

export const BuyHolderCard = styled.div`
    padding: 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 127, 255, 0.09);
    height:100%;

    @media ${THEME.MOBILEL} {
        padding: 69px 54px 76px;
    }

    & h2{
        font-family: 'Yeseva One', serif;
        font-size:25px;
        background: linear-gradient(180deg, #007FFF 0%, #0051A2 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @media ${THEME.MOBILEL} {
            font-size:40px;
        }
    }
`

export const BuyHolderTitle = styled.h2`
    font-size:18px;
    font-weight:600;
    color:#007FFF;
    margin-top:10px;
    margin-bottom:15px;

    @media ${THEME.MOBILEL} {
        font-size:24px;
    }
`

export const BuyHolderSubTitle = styled.h2`
display:flex;
column-gap:10px;
    font-size:16px;
    font-weight:600;
    color:#000;

    @media ${THEME.MOBILEL} {
        font-size:20px;
    }
`
export const BtnHolder = styled.div`
    display:flex;
    justify-content:center;
    margin-top:45px;
    border-top : 1px dashed #000;
    padding-top:45px;
    
    & button {
        border-radius: 10px;
        transition:0.5s;
        background: linear-gradient(180deg, #007FFF 0%, #0051A2 100%);
        height:65px;
        width:300px;
        color:#fff;
        font-size:28px;
        font-weight:700;
        border:none;
        cursor: pointer;
        &:hover {  // Use &:hover instead of :hover
            transform: translateY(-10px);
        }
    }
    .button1 {
        border-radius: 10px;
        transition:0.5s;
        background: linear-gradient(180deg, #007FFF 0%, #0051A2 100%);
        height:65px;
        width:300px;
        color:#fff;
        font-size:28px;
        font-weight:700;
        display: flex;
        align-items: center;
        justify-content: center;
        border:none;
        cursor: pointer;
        &:hover {  // Use &:hover instead of :hover
            transform: translateY(-10px);
        }
    }

`

export const Holder = styled.div`
    background:${THEME.white};
    border-radius:8px;
    cursor: pointer;
    height:100%;
    padding:10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition:0.3s ease-in;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    gap:10px;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        transform:translateY(-5px);
    }

    span{
        font-size:1rem;
        font-weight:800;
        color:${THEME.primary};
    }
`