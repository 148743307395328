import React, { useEffect, useState } from 'react'
import { SectionTitle, VideoHolder, VideoSectionHolder } from '../style'
import video from '../../../assets/480.mov'
import Button from '../../../components/Form/CustomButton'
import { CiLock } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentBookerUser } from '../BookingSlice';
import { CustomModal } from '../../../components/CustomModal';
import BookingForm from './BookingForm';

const VideoSection = () => {
    const [play, setPlay] = useState(false)

    const bookeruser = useSelector(selectCurrentBookerUser);

    useEffect(() => {
        if (Object.entries(bookeruser).length != 0) {
            setPlay(true)
        } else {
            setPlay(false);
        }
    }, [bookeruser])


    const [reset, setReset] = useState(0)
    const [trigger, setTrigger] = useState(0)

    // ======  Modal Open ========
    const [isModalOpen, setIsModalOpen] = useState(false);

    // ======  Modal Title and Content ========
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [modalwidth, setModalWidth] = useState(0)
    const FormReset = () => {
        setReset(reset + 1)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        FormReset()
    };

    const submitBooking = () => {
        setTrigger(trigger + 1)
        setModalContent(<BookingForm handleOk={handleOk} trigger={trigger} />);
        setModalTitle("Book A Free Demo");
        setModalWidth(500)
        showModal();
    };

    let content;

    if (play) {
        content = (<video src={video} loop muted autoPlay />)
    } else {
        content = (<div className="lock">
            <h2 className='title'>Get In Touch With Us Now!</h2>
            <span className='btn' onClick={() => submitBooking()}>Book a Free Demo</span>
        </div>)
    }
    return (
        <VideoSectionHolder id='video'>
            <div className="video__container">
                <SectionTitle>Say goodbye to growth blockers</SectionTitle>

                <p>Hi Biller powers your subscription business with the flexibility and agility it needs to succeed from testing offers and promotions to integrations and insights.</p>

                <VideoHolder>
                    {/* <div className='dark'></div> */}
                    {/* {content} */}
                    <div className="lock">
            <h2 className='title'>Get In Touch With Us Now!</h2>
            <span className='btn' onClick={() => submitBooking()}>Book a Free Demo</span>
        </div>
                </VideoHolder>
            </div>


            <CustomModal isVisible={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}
                width={modalwidth}
                modalTitle={modalTitle} modalContent={modalContent} />
        </VideoSectionHolder>
    )
}

export default VideoSection
